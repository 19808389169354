import React, { useEffect, useState } from "react";

import { getAllUsers } from "../../../api/UsersApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import SuccessMsg from "../../../components/dashboard/pop/SuccessMsg";
import CheckDelete from "../../../components/dashboard/pop/CheckDelete";
import { useStateContext } from "../../../context/ContextProvider";
import UserForm from "../../../components/dashboard/forms/UserForm";
import Pagination from "../../../components/Pagination";

export default function UsersTable({
  editingUser,
  search,
  submitLoader,
  setSubmitLoader,
  roles,
  rolesLoading,
  removeUser,
  data,
}) {
  const [open, setOpen] = useState(false);

  // FORMAT DATE ( 2023-06-15T12:33:03.000000Z => Jun 15, 2023 )
  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };
  const { user } = useStateContext();
  // POP MESSGAE
  const [msg, setMessage] = useState({ display: false, theme: "", name: "" });
  const [elementId, setElementId] = useState(null);
  const [elementName, setName] = useState("");
  const [remove, setRemove] = useState(false);
  const [editUser, setEditUser] = useState([]);

  // HANDLE DELETE USER
  const handleDelete = (id, name) => {
    setElementId(id);
    setRemove(true);
    setName(name);
  };

  useEffect(() => {}, [data]);
  const handleEdit = (id) => {
    setEditUser(data.data.filter((item) => item.id == id));
    setOpen(!open);
  };
  if (rolesLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <table className="w-full border-2xl p-8">
        <SuccessMsg display={msg.display} theme={msg.theme} name={msg.name} />

        <thead>
          <tr>
            <th>User</th>
            <th>Joined On</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data
            ? data.data
                .filter((item) => item.id !== user.id)
                .filter(
                  (item) =>
                    item.username
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                    item.email.toLowerCase().includes(search.toLowerCase())
                )
                .map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <div className="w-full flex items-center gap-2">
                          {item.avatar ? (
                            <img src={item.avatar} alt="" />
                          ) : (
                            <span class="material-symbols-outlined text-white bg-blue-500 rounded-full p-1">
                              person
                            </span>
                          )}
                          <div>
                            <p className="font-extralight text-sm">
                              {item.username}
                            </p>
                            <span className="font-thin text-gray-500 text-sm">
                              {item.email}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>{formatDate(item.created_at)}</td>
                      <td>{item.role.name}</td>
                      <td>
                        <button
                          className=""
                          onClick={() => {
                            handleDelete(item.id, item.username);
                          }}
                        >
                          <span class="material-symbols-outlined text-2xl !text-red-500">
                            delete
                          </span>
                        </button>
                        <button
                          className="text-secondarycolor "
                          onClick={() => {
                            handleEdit(item.id);
                          }}
                        >
                          <span class="material-symbols-outlined text-2xl">
                            edit
                          </span>
                        </button>
                      </td>
                    </tr>
                  );
                })
            : ""}
        </tbody>
      </table>
      {/* <Pagination data={data} setPage={setPage} /> */}
      <UserForm
        editingUser={editingUser}
        editUser={editUser}
        setOpen={setOpen}
        open={open}
        roles={roles?.data}
        submitLoader={submitLoader}
        setSubmitLoader={setSubmitLoader}
      />
      {/* DELETE */}
      <CheckDelete
        submitLoader={submitLoader}
        setSubmitLoader={setSubmitLoader}
        remove={remove}
        setRemove={setRemove}
        object="User"
        elementId={elementId}
        elementName={elementName}
        removeFun={removeUser}
        setElementId={setElementId}
      />
    </>
  );
}
