import { Skeleton } from '@mui/material'

export default function AdminTableLoader() {

    const fakeData = [1, 2, 3, 4, 5]

  return (
    <table>
        <thead>
            <tr>
                <th>ETL</th>
                <th>Decription</th>
                <th>Satus</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {
                fakeData.map(fake=>{
                    return  <tr key={fake}>
                                <td><Skeleton variant="text" sx={{ fontSize: '.7em' , width:'70%'}} /></td>
                                <td><Skeleton variant="text" sx={{ fontSize: '.7em' , width:'70%'}} /></td>
                                <td><Skeleton variant="text" sx={{ fontSize: '.7em' , width:'70%'}} /></td>
                                <td><Skeleton variant="text" sx={{ fontSize: '.7em' , width:'70%'}} /></td>
                            </tr>
                })
            }
        </tbody>
    </table>
  )
}
