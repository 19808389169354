import React, { useEffect, useState } from "react";
import ConnectorCard from "../components/dashboard/cards/ConnectorCard";
import ConnectorTable from "../components/dashboard/tables/ConnectorTable";
import ConnectorForm from "../components/dashboard/forms/ConnectorForm";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Backdrop, CircularProgress } from "@mui/material";
import {
  createConnector,
  deleteConnector,
  duplicateConnector,
  getAllConnectors,
  updateConnector,
} from "../api/ConnectorsApi";
import SuccessMsg from "../components/dashboard/pop/SuccessMsg";
import CardLoader from "../components/dashboard/loaders/CardLoader";
import EmptyData from "../components/dashboard/empty/EmptyData";
import Layout from "../components/dashboard/layouts/Layout";
import Pagination from "../components/Pagination";

export default function Connectors() {
  // INOF STYLE
  const [infoStyle, setInfoStyle] = useState("table");
  const [search, setSearch] = useState("");

  // POP MESSGAE
  const [msg, setMessage] = useState({ display: false, theme: "", name: "" });

  // GET FORM
  const [getForm, setForm] = useState(false);
  const queryClient = useQueryClient();
  const [reason, setReason] = useState("create");
  const [elementId, setElementId] = useState(null);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    if (search) {
      // If there's a search term, reset page to 1 and set perPage to 9999
      setPage(1);
      setPerPage(9999);
    } else if (!search && perPage !== 10) {
      // If the search term is cleared and perPage is not 5, reset perPage to 5
      setPerPage(10);
    }
  }, [search, perPage]);

  // CONNECTOR INFORMATIONS
  const getConnectors = useQuery(["getConnectors", page, search, perPage], () =>
    getAllConnectors(page, perPage, search)
  );

  // ADD CONNECTOR
  const addConnector = useMutation(createConnector, {
    onSuccess: () => {
      queryClient.invalidateQueries(["connectors"]);
      getConnectors.refetch();
      setMessage({ display: true, theme: "create", name: "Connector" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
    onSettled: () => {
      setSubmitLoader(false);
    },
  });

  // UPDATE CONNECTOR
  const editConnector = useMutation(updateConnector, {
    onSuccess: () => {
      queryClient.invalidateQueries(["connectors"]);
      getConnectors.refetch();
      setMessage({ display: true, theme: "update", name: "Connector" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
    onSettled: () => {
      setSubmitLoader(false);
    },
  });

  // DELETE CONNECTOR
  const removeConnector = useMutation(deleteConnector, {
    onSuccess: () => {
      queryClient.invalidateQueries(["connectors"]);
      getConnectors.refetch();
      setMessage({ display: true, theme: "delete", name: "Connector" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
    onSettled: () => {
      setSubmitLoader(false);
    },
  });

  // DUPLICATE CONNECTOR
  const copyConnector = useMutation(duplicateConnector, {
    onSuccess: () => {
      queryClient.invalidateQueries(["connectors"]);
      getConnectors.refetch();
      setMessage({ display: true, theme: "copy", name: "Connector" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
      setSubmitLoader(false);
    },
    onSettled: () => {
      setSubmitLoader(false);
    },
  });

  return (
    <div className="connectors">
      <SuccessMsg display={msg.display} theme={msg.theme} name={msg.name} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* TOP */}
      <div className="top">
        <Layout title="Connectors" />
        <button
          onClick={() => {
            setForm(!getForm);
          }}
        >
          <span class="material-symbols-outlined">add</span>
          <p>Add Connector</p>
        </button>
      </div>
      {/* FILTER */}
      <div className="filter-style">
        <div className="filter">
          <span class="material-symbols-outlined">search</span>
          <input
            type="text"
            placeholder="Search by connector name..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="style">
          <button
            className={infoStyle == "card" ? "grid" : ""}
            onClick={() => {
              setInfoStyle("card");
            }}
          >
            <span class="material-symbols-outlined">grid_view</span>
          </button>
          <button
            className={infoStyle == "table" ? "grid" : ""}
            onClick={() => {
              setInfoStyle("table");
            }}
          >
            <span class="material-symbols-outlined">view_agenda</span>
          </button>
        </div>
      </div>
      {/* INFORMATION */}
      {getConnectors.isLoading ? (
        <CardLoader />
      ) : getConnectors.data.data && getConnectors.data.data.length > 0 ? (
        infoStyle == "card" ? (
          <ConnectorCard data={getConnectors.data.data} search={search} />
        ) : (
          <ConnectorTable
            submitLoader={submitLoader}
            setSubmitLoader={setSubmitLoader}
            data={getConnectors.data.data}
            copyFun={copyConnector}
            removeFun={removeConnector}
            search={search}
            setForm={setForm}
            setReason={setReason}
            elementId={elementId}
            setElementId={setElementId}
          />
        )
      ) : (
        <EmptyData type="connector" />
      )}
      {/* ADD FORM */}
      <Pagination
        data={getConnectors.data}
        page={page}
        setPage={setPage}
        search={search}
      />
      <ConnectorForm
        submitLoader={submitLoader}
        setSubmitLoader={setSubmitLoader}
        reason={reason}
        getForm={getForm}
        setReason={setReason}
        setForm={setForm}
        addFun={addConnector}
        updateFun={editConnector}
        allConnectors={getConnectors.data?.data}
        elementId={elementId}
      />
    </div>
  );
}
