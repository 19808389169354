import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import { useStateContext } from '../../context/ContextProvider';
import axiosClient from '../../api/Authentication';
import ErrorMsg from '../../components/dashboard/pop/ErrorMsg';

export default function AuthAdmin() {

    // SELECT AUTH TYPE
    const [auth, setAuth] = useState('login')

    const navigate = useNavigate();
    const [submitLoder, setSubmitLoader] = useState(false)

    // USER INFO
    const {setUser, setToken} = useStateContext()
    const [errors, setErrors] = useState(null)
    const [displayError, setDisplayError] = useState(false)

    // FORM VALUES
    const usernameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()

    // HANDLE SUBMIT
    const handleSubmit = (e) =>{
        setSubmitLoader(true)
        e.preventDefault()
        const payload = {
            email : emailRef.current.value,
            password : passwordRef.current.value,
        }
        axiosClient.post('/login', payload)
        .then(({data}) => {
            if(data.data.user.is_admin){
                setUser(data.data.user)
                setToken(data.data.token);
                navigate('/admin')
                setDisplayError(true)
                window.location.reload()
            }else{
                setErrors("Non-Admin Users Not Allowed")
                setDisplayError(true)
                setTimeout(() => {
                    setDisplayError(false)
                }, 5000);
            }
            setSubmitLoader(false)
        })
        .catch(err => {
            setSubmitLoader(false)
            const response = err.response;
            setErrors(response.data.message)
            setDisplayError(true)
            setTimeout(() => {
                setDisplayError(false)
            }, 5000);
        })
    }

  return (
    <div className='auth-user auth-admin'>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={submitLoder}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <ErrorMsg text={errors} display={displayError} />
        {/* LEFT */}
        <div className="left">
            <div className="text">
              <h1>Empower Your Admin Experience</h1>
              <p>"Simplify Your Administrative Tasks with Advanced Tools and Features"</p>
            </div>
            <div className="nodes">
                <div className="nd-source">
                    <div className="node">
                        <span class="material-symbols-outlined">sensors</span>
                        <p>Connector</p>
                    </div>
                </div>
                <div className="nd-transform">
                    <div className="node">
                        <span class="material-symbols-outlined">database</span>
                        <p>Dataset</p>
                    </div>
                </div>
                <div className="nd-output">
                    <div className="node">
                        <span class="material-symbols-outlined">conversion_path</span>
                        <p>ETL</p>
                    </div>
                </div>
            </div>
        </div>
        {/* RIGHT */}
        <div className="right">
            {/* LOGO */}
            <div className="logo">
                {/* <img src={require('../../resources/assets/images/logo.png')} alt="" /> */}
                <h3><span>H</span> i Group </h3>
            </div>
            {/* SELECT AUTH TYPE */}
            <div className="select-auth">
                <button style={{width: "100%"}} onClick={()=>{ setAuth('login') }} className={auth == 'login' ? 'active' : ''}>
                    Admin
                </button>
            </div>
            {/* FORM */}
            <form onSubmit={handleSubmit}>
                <div className="inp">
                    <span class="material-symbols-outlined">mail</span>
                    <input type="email" placeholder='Email' ref={emailRef} />
                </div>
                <div className="inp">
                    <span class="material-symbols-outlined">password</span>
                    <input type="password" placeholder='Password' ref={passwordRef} />
                </div>
                <div className="submit">
                    <button>Sign in to admin panel</button>
                </div>
                <p>By logging into your admin account, you agreeto our Terms of Service and Privacy Cookie Statement.</p>
            </form>
        </div>
    </div>
  )
}
