import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

export default function CheckDelete({
  remove,
  setRemove,
  theme,
  elementId,
  elementName,
  removeFun,
  submitLoader,
  error,
  setSubmitLoader,
  setError,
  setLoader,
}) {
  const handleClose = () => {
    setRemove(false);
    if (error && setError) {
      setError("");
    }
  };

  const deleteItem = async (e) => {
    e.preventDefault();

    try {
      if (setSubmitLoader) {
        setSubmitLoader(true);
      }

      // Trigger the remove function from the parent
      await removeFun.mutateAsync(elementId);

      // Close the modal after successful deletion
      setRemove(false);
    } catch (error) {
      // Handle error if needed
    } finally {
      if (setSubmitLoader) {
        setSubmitLoader(false);
      }
    }
  };

  return (
    <div className={theme ? "pop pop-admin" : "pop"}>
      {/* LOADER */}
      {submitLoader && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={submitLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {/* POP */}
      <div className={remove ? "delete-check show" : "delete-check"}>
        <span className="material-symbols-outlined">delete</span>
        <h3>Delete {elementName ? `"${elementName}"` : `#${elementId}`}</h3>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <p>Sure you want to delete.</p>
        <div className="actions">
          <button onClick={(e) => deleteItem(e)}>Delete</button>
          <button
            className="cancel"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
      {/* POP BG */}
      <div className={remove ? "pop-bg bg" : "pop-bg"}></div>
    </div>
  );
}
