import React, { useState } from 'react'
import PlaygroundTable from '../../components/admin/users/PlaygroundTable'
import PlaygroundForm from '../../components/admin/forms/PlaygroundForm'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createPlayground, deletePlayground, getAllPlaygrounds, updatePlayground } from '../../api/PlaygroundApi';
import SuccessMsg from '../../components/dashboard/pop/SuccessMsg';

export default function Playground() {

    const [getForm, setForm] = useState(false)
    const [reason, setReason] = useState("create")
    const [elementId, setElementId] = useState(null)

     // POP MESSGAE
    const [msg, setMessage] = useState({ display: false, theme: '', name: '' })

    const queryClient = useQueryClient()

     // ADD PLAYGROUND
    const addPlayground = useMutation(createPlayground, {
        onSuccess: () => {
            queryClient.invalidateQueries(["playground"]);
            getPlaygounds.refetch()
            setMessage({ display: true, theme: 'create', name: 'Playground' })
            setTimeout(() => {
                setMessage({ display: false, theme: '', name: '' })
            }, 3000);
        },
    });

    // UPDATE PLAYGROUND
    const editPlayground = useMutation(updatePlayground, {
        onSuccess: () => {
            queryClient.invalidateQueries(["playground"]);
            getPlaygounds.refetch()
            setMessage({ display: true, theme: 'update', name: 'Playground' })
            setTimeout(() => {
                setMessage({ display: false, theme: '', name: '' })
            }, 3000);
        },
    });

    // DELETE PLAYGROUND
    const removePlayground = useMutation(deletePlayground, {
        onSuccess: () => {
        queryClient.invalidateQueries(["playground"]);
        getPlaygounds.refetch()
        setMessage({ display: true, theme: 'delete', name: 'Playground' })
            setTimeout(() => {
                setMessage({ display: false, theme: '', name: '' })
            }, 3000);
        },
    });

    // GET ALL USERS 
    const getPlaygounds = useQuery(['getPlaygounds'], () => getAllPlaygrounds() )

  return (
    <div className='panel playground'>
        <SuccessMsg display={msg.display} theme={msg.theme} name={msg.name} />
        <button className='add' onClick={()=>{ setForm(!getForm) }}>
            <span class="material-symbols-outlined">add</span>
            <p>Add Playground</p>
        </button>
        <div className="table-info">
            <div className="title-card">
                <h3>Playground</h3>
            </div>
            <PlaygroundTable 
                playgounds={getPlaygounds} removeFun={removePlayground}
                setForm={setForm} setReason={setReason} elementId={elementId} setElementId={setElementId}
            />
            <PlaygroundForm 
                getForm={getForm} setForm={setForm} playgounds={getPlaygounds} elementId={elementId}
                addFun={addPlayground} updateFun={editPlayground} reason={reason} setReason={setReason} 
            />
        </div>
    </div>
  )
}
