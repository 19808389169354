import axios from "axios";
import { API_URL, TOKEN } from '../conf.js';

const axiosClient = axios.create({
    baseURL : `${API_URL}api`,
})

axiosClient.interceptors.request.use((config)=>{
    const token = localStorage.getItem('ACCESS_TOKEN_USER')  
    config.headers.Authorization = `Bearer ${token}`
    return config;
})

axiosClient.interceptors.request.use((response)=>{
    return response;
}, (error) => {
    const {response} = error;
    if(response.status === 401){
        localStorage.removeItem('ACCESS_TOKEN_USER')  
    }

    throw error;
})


export default axiosClient;