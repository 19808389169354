import "./resources/style/Main.scss";
import "./resources/style/Cards.scss";
import "./resources/style/Tables.scss";
import "./resources/style/Forms.scss";
import "./resources/style/MediaQuery.scss";
import "./resources/style/Settings.scss";
import "./index.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ScrollToTop from "./functions/ScrollToTop";
import { Route, Routes } from "react-router-dom";
import AuthContext from "./context/AuthContext";
import Auth from "./pages/auth/Auth";
import AuthAdmin from "./pages/auth/AuthAdmin";
import DashboardContext from "./context/DashboardContext";
import Connectors from "./pages/Connectors";
import Datasets from "./pages/Datasets";
import Etl from "./pages/Etl";
import Flow from "./pages/Flow";
import EtlRun from "./pages/EtlRun";
import Run from "./pages/Run";
import AdminContext from "./context/AdminContext";
import Overview from "./pages/admin/Overview";
import Users from "./pages/admin/Users";
import Userss from "./pages/Settings/Users";
import Playground from "./pages/admin/Playground";
import Error404 from "./pages/errors/Error404";
import Settings from "./pages/Settings/Settings";
import Roles from "./pages/Settings/Roles";
import Workflow from "./pages/workflow/index";
import Catalog from "./pages/Catalog";
import ChatBot from "./pages/ChatBot";
import SampleDb from "./pages/SampleDb";

function App() {
  const queryClient = new QueryClient();

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ScrollToTop />
        <Routes>
          {/* ------------- ERROR ROUTE ------------- */}
          <Route path="*" element={<Error404 />} />

          {/* ------------- PRIVATE ROUTES ------------- */}
          <Route path="/" element={<AuthContext />}>
            <Route path="" element={<Auth />} />
            <Route path="panel" element={<AuthAdmin />} />
          </Route>

          {/* ------------- PUBLIC ROUTES ------------- */}

          {/* >>>>>>>>>>>>> USER DASHBOARD <<<<<<<<<<<< */}
          <Route path="/user" element={<DashboardContext />}>
            <Route path="settings/roles" element={<Roles />} />
            <Route path="" element={<Connectors />} />
            <Route path="datasets" element={<Datasets />} />
            <Route path="etl" element={<Etl />} />
            <Route path="etl_run/:id" element={<EtlRun />} />
            <Route path="catalog/:id" element={<Catalog />} />
            <Route path="sampleDb/:id/:table" element={<SampleDb />} />
            <Route path="run/:id" element={<Run />} />
            <Route path="flow/:id" element={<Flow />} />
            <Route path="workflow" element={<Workflow />} />
            <Route path="ai" element={<ChatBot />} />
            <Route path="settings" element={<Settings />} />
            <Route path="settings/users" element={<Userss />} />
          </Route>

          {/* >>>>>>>>>>>>> ADMIN DASHBOARD <<<<<<<<<<<< */}
          <Route path="/admin" element={<AdminContext />}>
            <Route path="" element={<Overview />} />
            <Route path="users" element={<Users />} />
            <Route path="playground" element={<Playground />} />
          </Route>
        </Routes>
      </QueryClientProvider>
    </div>
  );
}

export default App;
