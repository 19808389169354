import axios from "axios";
import { API_URL, TOKEN } from '../conf.js';


// GET ALL PLAYGROUNDS
export async function getAllPlaygrounds() {
  const response = await axios.get(
    `${API_URL}api/data/playgrounds`
  );
  return response.data.data;
}

// GET PLAYGROUNDS BY USER
export async function getAllPlaygroundsByUser() {
  const response = await axios.get(
    `${API_URL}api/playgrounds`,
    { headers: { Authorization: `Bearer ${TOKEN}` }}
  );
  return response.data.data;
}

// GET PLAYGROUND BY ID
export async function getOnePlayground(id) {
  const response = await axios.get(
    `${API_URL}api/playgrounds/${id}`,
    { headers: { Authorization: `Bearer ${TOKEN}` }}
  );
  return response.data.data;
}

// CREATE A NEW PLAYGROUND
export async function createPlayground(data) {
  return await axios.post(
    `${API_URL}api/playgrounds`,
    data,
    { headers: { Authorization: `Bearer ${TOKEN}` }}
  );
}

// UPDATE PLAYGROUND
export async function updatePlayground(data) {
  const { name, type, creativity, user_id } = data;
  return await axios.put(
    `${API_URL}api/playgrounds/${data.id_playground}`,
    {
      name,
      type,
      creativity,
      user_id
    },
    { headers: { Authorization: `Bearer ${TOKEN}` }},
  );
}

// DELETE PLAYGROUND
export async function deletePlayground(id) {
  return await axios.delete(
    `${API_URL}api/playgrounds/${id}`,
    { headers: { Authorization: `Bearer ${TOKEN}` }}
  );
}
