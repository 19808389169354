// import { Tooltip } from '@mui/material'
import React, { useState } from "react";
import CheckDelete from "../pop/CheckDelete";


export default function DatasetTable({
  data,
  copyFun,
  removeFun,
  setReason,
  setForm,
  elementId,
  setElementId,
  search,
  submitLoader,
  setSubmitLoader,
}) {
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const [remove, setRemove] = useState(false);

  // REMOVE ELEMENT NAME
  const [elementName, setName] = useState("");

  const handleDelete = (id, name) => {
    setElementId(id);
    setRemove(true);
    setName(name);
  };

  const handleUpdate = (id) => {
    setElementId(id);
    setReason("update");
    setForm(true);
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Dataset</th>
          <th>Description</th>
          <th>Connector</th>
          <th>Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, i) => {
          return (
            item.name.toLowerCase().indexOf(search.toLowerCase()) > -1 && (
              <tr key={i}>
                <td data-label="Dataset">
                  <div className="main">
                    <span class="material-symbols-outlined">account_tree</span>
                    <p>{item.name}</p>
                  </div>
                </td>
                <td data-label="Description">
                  {item.description ? item.description : "---"}
                </td>
                <td data-label="Connector">
                  <span className="type">
                    {item.connector ? item.connector.name : "---"}
                  </span>
                </td>
                <td data-label="Date">{formatDate(item.created_at)}</td>
                <td>
                  <button
                    onClick={() => {
                      copyFun.mutate(item.id_dataset);
                    }}
                  >
                    <span className="material-symbols-outlined">file_copy</span>
                  </button>
                  <button
                    onClick={() => {
                      handleUpdate(item.id_dataset);
                    }}
                  >
                    <span className="material-symbols-outlined">edit</span>
                  </button>
                  <button
                    onClick={() => {
                      handleDelete(item.id_dataset, item.name);
                    }}
                  >
                    <span className="material-symbols-outlined">Delete</span>
                  </button>
                </td>
              </tr>
            )
          );
        })}
      </tbody>

      {/* DELETE */}
      <CheckDelete
        submitLoader={submitLoader}
        setSubmitLoader={setSubmitLoader}
        remove={remove}
        setRemove={setRemove}
        object="Dataset"
        elementId={elementId}
        elementName={elementName}
        removeFun={removeFun}
        setElementId={setElementId}
      />
    </table>
  );
}
