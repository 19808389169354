import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { useStateContext } from "../../context/ContextProvider";
import axiosClient from "../../api/Authentication";
import ErrorMsg from "../../components/dashboard/pop/ErrorMsg";

export default function Auth() {
  // SELECT AUTH TYPE
  const [auth, setAuth] = useState("login");

  const navigate = useNavigate();
  const [submitLoder, setSubmitLoader] = useState(false);

  // USER INFO
  const { setUser, setToken } = useStateContext();
  const [errors, setErrors] = useState(null);
  const [displayError, setDisplayError] = useState(false);

  // FORM VALUES
  const usernameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();

  // HANDLE SUBMIT
  const handleSubmit = (e) => {
    setSubmitLoader(true);
    e.preventDefault();
    if (auth == "login") {
      const payload = {
        email: emailRef.current.value,
        password: passwordRef.current.value,
      };
      axiosClient
        .post("/login", payload)
        .then(({ data }) => {
          setUser(data.data.user);
          setToken(data.data.token);
          navigate("/user");
          setSubmitLoader(false);
          setDisplayError(false);
          window.location.reload();
        })
        .catch((err) => {
          setSubmitLoader(false);
          const response = err.response;
          setErrors(response.data.message);
          setDisplayError(true);
          setTimeout(() => {
            setDisplayError(false);
          }, 5000);
        });
    } else {
      const payload = {
        email: emailRef.current.value,
        password: passwordRef.current.value,
        username: usernameRef.current.value,
        is_admin: false,
      };
      axiosClient
        .post("/register", payload)
        .then(({ data }) => {
          setUser(data.data.user);
          setToken(data.data.token);
          navigate("/user");
          setSubmitLoader(false);
          setDisplayError(false);
          window.location.reload();
        })
        .catch((err) => {
          setSubmitLoader(false);
          const response = err.response;
          setErrors(response.data.message);
          setDisplayError(true);
          setTimeout(() => {
            setDisplayError(false);
          }, 5000);
        });
    }
  };

  return (
    <div className="auth-user">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitLoder}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ErrorMsg text={errors} display={displayError} />
      {/* LEFT */}
      <div className="left">
        <div className="text">
          <h1>Unlock the Potential of Your DB</h1>
          <p>
            "Streamline Your Database Workflow with Our Drag & Drop SQL Query
            Tool"
          </p>
        </div>
        <div className="nodes">
          <div className="nd-source">
            <div className="node">
              <span className="material-symbols-outlined">extension</span>
              <p>Source</p>
            </div>
          </div>
          <div className="nd-transform">
            <div className="node">
              <span className="material-symbols-outlined">pentagon</span>
              <p>Transform</p>
            </div>
          </div>
          <div className="nd-output">
            <div className="node">
              <span className="material-symbols-outlined">folder_open</span>
              <p>Output</p>
            </div>
          </div>
        </div>
      </div>
      {/* RIGHT */}
      <div className="right">
        {/* LOGO */}
        <div className="logo">
          <img src={require("../../resources/assets/images/logo.png")} alt="" />
          <h3>i Group </h3>
        </div>
        {/* SELECT AUTH TYPE */}
        <div className="select-auth justify-center">
          <p>Sign In</p>
          {/* <button
            onClick={() => {
              setAuth("register");
            }}
            className={auth == "register" ? "active" : ""}
          >
            Create account
          </button> */}
        </div>
        {/* FORM */}
        <form onSubmit={handleSubmit}>
          {auth == "register" && (
            <div className="inp">
              <span className="material-symbols-outlined">person</span>
              <input type="text" placeholder="Username" ref={usernameRef} />
            </div>
          )}
          <div className="inp">
            <span className="material-symbols-outlined">mail</span>
            <input type="email" placeholder="Email" ref={emailRef} />
          </div>
          <div className="inp">
            <span className="material-symbols-outlined">password</span>
            <input type="password" placeholder="Password" ref={passwordRef} />
          </div>
          <div className="submit">
            {auth == "register" ? (
              <button>Sign up with Hi Group</button>
            ) : (
              <button>Sign in with Hi Group</button>
            )}
          </div>
          <p>
            By creating an account, you agreeto our Terms of Service and Privacy
            Cookie Statement.
          </p>
        </form>
      </div>
    </div>
  );
}
