import axios from "axios";
import { API_URL, TOKEN } from "../conf.js";

// FETCH ALL USERS
export async function getAllUsers(page, per_page) {
  const response = await axios.get(
    `${API_URL}api/data/users?page=${page}&per_page=${per_page}`
  );

  return response.data.data;
}

// ADD USER
export async function addUser(data) {
  return await axios.post(`${API_URL}api/users/add`, data, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}
// SHOW ONE USER
export async function showUser(id) {
  return await axios.get(`${API_URL}api/user/${id}`, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}
// EDIT USER
export async function editUser(data) {
  return await axios.patch(`${API_URL}api/user/edit/${data.id}`, data, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}
// DELETE USER
export async function deleteUser(id) {
  return await axios.delete(`${API_URL}api/users/${id}`, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}
