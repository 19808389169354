import axios from "axios";
import { API_URL } from "../conf.js";

// GET ALL LOGS
export async function getRuns(id) {
  const response = await axios.get(`${API_URL}api/logs?id=${id}`);
  return response.data;
}

// GET ALL LOGS
export async function getAllLogs(id) {
  const response = await axios.get(`${API_URL}api/logs/${id}`);
  return response.data;
}

// GET RUN STATUS
export async function getRunStatus(id) {
  const response = await axios.get(`${API_URL}api/logs/status/${id}`);
  return response.data;
}

// CREATE A NEW RUN
export async function createRun(json_plan) {
  // data!!
  // console.info(json_plan)
  // console.info(JSON.parse(json_plan));
  // console.info(JSON.stringify(json_plan))
  // var base64Encoded = btoa(json_plan);
  // console.info(base64Encoded)

  const response = await axios.post(`${API_URL}api/logs`, { json_plan });
  return response.data;
}
