import React, { useEffect, useState } from "react";
import EtlCard from "../components/dashboard/cards/EtlCard";
import EtlTable from "../components/dashboard/tables/EtlTable";
import EtlForm from "../components/dashboard/forms/EtlForm";
import {
  postEtl,
  putEtl,
  RemoveEtl,
  getAllEtls,
  duplicateEtl,
} from "../api/EtlApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import SuccessMsg from "../components/dashboard/pop/SuccessMsg";
import TableLoader from "../components/dashboard/loaders/TableLoader";
import EmptyData from "../components/dashboard/empty/EmptyData";
import { createRun } from "../api/LogApi";
import { createEtlRun } from "../api/EtlRunApi";
import { Backdrop, CircularProgress } from "@mui/material";
import ErrorMsg from "../components/dashboard/pop/ErrorMsg";
import Layout from "../components/dashboard/layouts/Layout";
import Pagination from "../components/Pagination";

export default function Etl() {
  // USER INFO
  // INOF STYLE
  const [infoStyle, setInfoStyle] = useState("table");
  const [search, setSearch] = useState("");

  // POP MESSGAE
  const [msg, setMessage] = useState({ display: false, theme: "", name: "" });
  const [error, setError] = useState({ display: false, text: "" });

  // LOADER
  const [submitLoder, setSubmitLoader] = useState(false);

  // GET FORM
  const [getForm, setForm] = useState(false);
  const queryClient = useQueryClient();
  const [reason, setReason] = useState("create");
  const [elementId, setElementId] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  // ETL INFORMATIONS
  const getEtls = useQuery(["getEtl", page, search, perPage], () =>
    getAllEtls(page, perPage, search)
  );
  useEffect(() => {
    if (search) {
      // If there's a search term, reset page to 1 and set perPage to 9999
      setPage(1);
      setPerPage(9999);
    } else if (!search && perPage !== 10) {
      // If the search term is cleared and perPage is not 5, reset perPage to 5
      setPerPage(10);
    }
  }, [search, perPage]);

  // ADD ETL
  const addEtl = useMutation(postEtl, {
    onSuccess: () => {
      queryClient.invalidateQueries(["etl"]);
      getEtls.refetch();
      setMessage({ display: true, theme: "create", name: "Etl" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
    onSettled: () => {
      setSubmitLoader(false);
    },
  });

  // UPDATE ETL
  const editEtl = useMutation(putEtl, {
    onSuccess: () => {
      queryClient.invalidateQueries(["etl"]);
      getEtls.refetch();
      setMessage({ display: true, theme: "update", name: "Etl" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
    onSettled: () => {
      setSubmitLoader(false);
    },
  });

  // DELETE ETL
  const removeEtl = useMutation(RemoveEtl, {
    onSuccess: () => {
      queryClient.invalidateQueries(["etl"]);
      getEtls.refetch();
      setMessage({ display: true, theme: "delete", name: "Etl" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
  });

  // DUPLICATE ETL
  const copyEtl = useMutation(duplicateEtl, {
    onSuccess: () => {
      queryClient.invalidateQueries(["etls"]);
      getEtls.refetch();
      setMessage({ display: true, theme: "copy", name: "Etl" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
    onSettled: () => {
      setSubmitLoader(false);
    },
  });

  // ======= RUN =========
  const runEtl = useMutation(createEtlRun, {
    onSuccess: () => {
      queryClient.invalidateQueries(["etl_run"]);
    },
  });

  // >>>>>>>>>>> CREATE A NEW LOG <<<<<<<<<<<
  const createLog = useMutation(createRun, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["run"]);
      setMessage({ display: true, theme: "run log", name: "run" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);

      // >>>>>>>>>> Create a new Run <<<<<<<<<<<<

      // Generate Start/End Date
      const currentDate = new Date();
      const endDate = currentDate.toISOString().slice(0, 19).replace("T", " ");
      currentDate.setSeconds(currentDate.getSeconds() - 2);
      const startDate = currentDate
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      // Payload
      const payload = {
        id_run: data.id,
        start_date: startDate,
        end_date: endDate,
        logs: data.log.join(", "),
        metrics: data.state,
        code: "",
        carbon: "",
        id_etl: elementId,
      };
      runEtl.mutate(payload);

      setSubmitLoader(false);
    },
    onError: (data) => {
      setSubmitLoader(false);
      setError({ display: true, text: "Server Error!!" });
      setTimeout(() => {
        setError({ display: false, text: "" });
      }, 3000);
    },
  });

  const handleRun = (id) => {
    // CREATE A NEW LOG
    setSubmitLoader(true);
    const jsonPlan = getEtls.data.data.find((item) => {
      return item.id_etl == id;
    });
    id ? createLog.mutate(jsonPlan.json_plan) : setSubmitLoader(false);
  };
  return (
    <div className="etl">
      {/* LOADER */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 99 }}
        open={submitLoder}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* POP MESSGAE */}
      <SuccessMsg display={msg.display} theme={msg.theme} name={msg.name} />
      <ErrorMsg display={error.display} text={error.text} />

      {/* TOP */}
      <div className="top">
        <Layout title="ETL" />
        <button
          onClick={() => {
            setForm(!getForm);
          }}
        >
          <span class="material-symbols-outlined">add</span>
          <p>Add ETL</p>
        </button>
      </div>
      {/* FILTER */}
      <div className="filter-style">
        <div className="filter">
          <span className="material-symbols-outlined">search</span>
          <input
            type="text"
            placeholder="Search by ETL name..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="style">
          <button
            className={infoStyle == "card" ? "grid" : ""}
            onClick={() => {
              setInfoStyle("card");
            }}
          >
            <span class="material-symbols-outlined">grid_view</span>
          </button>
          <button
            className={infoStyle == "table" ? "grid" : ""}
            onClick={() => {
              setInfoStyle("table");
            }}
          >
            <span class="material-symbols-outlined">view_agenda</span>
          </button>
        </div>
      </div>

      {/* INFORMATION */}
      {getEtls.isLoading ? (
        <TableLoader />
      ) : getEtls.data && getEtls.data.data.length > 0 ? (
        infoStyle == "card" ? (
          <EtlCard data={getEtls.data.data} search={search} />
        ) : (
          <EtlTable
            handleRun={handleRun}
            data={getEtls.data.data}
            setSubmitLoader={setSubmitLoader}
            submitLoder={submitLoder}
            copyFun={copyEtl}
            removeFun={removeEtl}
            search={search}
            setForm={setForm}
            setReason={setReason}
            elementId={elementId}
            setElementId={setElementId}
          />
        )
      ) : (
        <EmptyData type="etl" />
      )}
      <Pagination page={page} setPage={setPage} data={getEtls.data} />
      {/* ADD FORM */}
      <EtlForm
        setSubmitLoader={setSubmitLoader}
        submitLoder={submitLoder}
        reason={reason}
        setReason={setReason}
        getForm={getForm}
        setForm={setForm}
        addFun={addEtl}
        updateFun={editEtl}
        allEtls={getEtls.data?.data}
        elementId={elementId}
      />
    </div>
  );
}
