import axios from "axios";
import { API_URL, TOKEN } from "../conf.js";
import { getAllLogs } from "./LogApi.js";

// *************** API URL **************
//const API_URL = "http://127.0.0.1:8000/"
//const TOKEN = localStorage.getItem('ACCESS_TOKEN_USER')

// GET ALL ETL RUNS
export async function getAllEtlRuns(id_etl, page, per_page) {
  const response = await axios.get(
    `${API_URL}api/etl_runs?id_etl=${id_etl}&page=${page}&per_page=${per_page}`,
    {
      headers: { Authorization: `Bearer ${TOKEN}` },
    }
  );
  return response.data.data;
}

// GET ETL RUN BY ID
export async function getOneEtlRun(id) {
  const response = await axios.get(`${API_URL}api/etl_runs/${id}`, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
  return response.data.data;
}

// CREATE A NEW ETL RUN
export async function createEtlRun(data) {
  return await axios.post(`${API_URL}api/etl_runs`, data, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}

// UPDATE ETL RUN
export async function updateEtlRun(data) {
  const { id_run, metrics, logs } = data;
  const run = await getOneEtlRun(id_run).then((res) => {
    return res;
  });
  const updatedRun = { ...run, metrics, logs };

  return await axios.put(`${API_URL}api/etl_runs/${id_run}`, updatedRun, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}

// DELETE ETL RUN
export async function deleteEtlRun(id) {
  return await axios.delete(`${API_URL}api/etl_runs/${id}`, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}
