import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../context/ContextProvider";
import { editUser } from "../../../api/UsersApi";
import SuccessMsg from "../pop/SuccessMsg";

const ProfileForm = ({ open, setOpen }) => {
  const { user, setToken, setUser } = useStateContext();
  const [username, setUsername] = useState(user ? user.username : "");
  const [email, setEmail] = useState(user ? user.email : "");
  const [password, setPassword] = useState("");
  const [msg, setMessage] = useState({ display: false, theme: "", name: "" });

  const [errors, setErrors] = useState({
    username: "",
    email: "",
    password: "",
  });

  // Return true if there are no errors

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      username,
      email,
      password,
      id: user.id,
      role_id: user.role_id,
    };
    editUser(data)
      .then((editedUser) => {
        // Update the user state with the edited user
        setUser(editedUser.data.data);

        // Close the form

        // Show success message
      })
      .catch((error) => {
        // Handle errors if any
        console.error("Error editing user:", error);
      });
    setMessage({ display: true, theme: "create", name: "User" });
    setTimeout(() => {
      setMessage({ display: false, theme: "", name: "" });
    }, 3000);
    setOpen(!open);
  };
  return (
    <>
      <SuccessMsg display={msg.display} theme={msg.theme} name={msg.name} />
      {/* BG CONTENT */}
      <div
        className="bg-form"
        style={{ display: open ? "block" : "none" }}
      ></div>
      {/* CONTENT */}
      <section
        className="connector-form"
        style={{
          transform: open
            ? "translate(-50%, 0) scale(1)"
            : "translate(-50%, 0) scale(0)",
        }}
      >
        {/* HEAD */}
        <div className="flex justify-between">
          <h3>Modify your profile</h3>
          <div className="head">
            <button onClick={() => setOpen(!open)}>
              <span className="material-symbols-outlined">close</span>
            </button>
          </div>
        </div>

        {/* FORM */}
        <form onSubmit={handleSubmit}>
          <div className="form">
            <div className="inps">
              <div className="inp">
                <label>Name *</label>
                <input
                  value={username}
                  name="username"
                  type="text"
                  placeholder="Enter Name"
                  onChange={(e) => setUsername(e.target.value)}
                />
                <div className="required-error">{errors.username}</div>
              </div>
              <div className="inp">
                <label>Email *</label>
                <input
                  value={email}
                  name="email"
                  type="text"
                  placeholder="Enter Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="required-error">{errors.email}</div>
              </div>
            </div>
            <div className="inps">
              <div className="inp">
                <label>Password *</label>
                <input
                  value={password}
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="required-error">{errors.password}</div>
              </div>
            </div>

            <div className="submit">
              <button type="button" onClick={() => setOpen(!open)}>
                Cancel
              </button>
              <button>Update</button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default ProfileForm;
