import axios from "axios";
import { API_URL, TOKEN } from '../conf.js';


// GET ALL USERS
export async function getAllUsers() {
  const response = await axios.get(
    `${API_URL}api/data/users`,
  );

  return response.data.data;
}


// DELETE USER
export async function deleteUser(id) {
  return await axios.delete(
    `${API_URL}api/users/${id}`,
    { headers: { Authorization: `Bearer ${TOKEN}` }}
  );
  
}