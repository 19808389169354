import React from 'react'
import UsersTable from '../../components/admin/users/UsersTable'

export default function Users() {
  return (
    <div className='panel users'>
        <div className="table-info">
            <div className="title-card">
                <h3>Users</h3>
            </div>
            <UsersTable />
        </div>    
    </div>
  )
}
