import React, { useEffect, useState } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import SuccessMsg from "../../../components/dashboard/pop/SuccessMsg";
import CheckDelete from "../../../components/dashboard/pop/CheckDelete";
import { useStateContext } from "../../../context/ContextProvider";
import RoleForm from "../../../components/dashboard/forms/RoleForm";
import { deleteRole, getAllRoles } from "../../../api/RolesApi";

export default function RolesTable({ limit, editingRole }) {
  const [open, setOpen] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [error, setError] = useState(null);
  // FORMAT DATE ( 2023-06-15T12:33:03.000000Z => Jun 15, 2023 )
  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  // POP MESSGAE
  const [msg, setMessage] = useState({ display: false, theme: "", name: "" });
  const [elementId, setElementId] = useState(null);
  const [elementName, setName] = useState("");
  const [remove, setRemove] = useState(false);
  const [editRole, setEditRole] = useState([]);
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();
  const removeRole = useMutation(deleteRole, {
    onSuccess: () => {
      queryClient.invalidateQueries(["roles"]);
      refetch();
      setMessage({ display: true, theme: "delete", name: "Role" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
    onError: (error) => {
      setSubmitLoader(false);

      if (error.response?.status === 500) {
        // Handle 500 errors
        const errorMessage = parse500ErrorMessage(
          error.response?.data?.message
        );
        setError(errorMessage);
      } else {
        // Handle other errors
        setError(error.response?.data?.msg || "An error occurred");
      }
    },
  });
  const parse500ErrorMessage = (message) => {
    // You can implement custom logic to extract meaningful information from the error message
    if (message.includes("Integrity constraint violation")) {
      return "Cannot delete the role because it is associated with users.";
    }
    // Add more conditions based on your requirements

    // If no specific condition is matched, return the original error message
    return message;
  };

  // HANDLE DELETE Role
  const handleDelete = (id, name) => {
    setElementId(id);
    setRemove(true);
    setName(name);
  };

  // useEffect(()=>{
  //   if()
  // },[])
  // HANDLE EDIT Role

  // ROLES INFO
  const { data, isLoading, refetch } = useQuery(["getRoles", page], () =>
    getAllRoles(page, 5)
  );
  const handleEdit = (id) => {
    setEditRole(data.data.filter((item) => item.id == id));
    setOpen(!open);
  };
  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <table className="w-full border-2xl p-8">
      <SuccessMsg display={msg.display} theme={msg.theme} name={msg.name} />
      <thead>
        <tr>
          <th>Name</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.data.map((item) => {
          return (
            <tr>
              <td>{item.name}</td>
              <td>
                <button
                  className=""
                  onClick={() => {
                    handleDelete(item.id, item.name);
                  }}
                >
                  <span class="material-symbols-outlined text-2xl !text-red-500">
                    delete
                  </span>
                </button>
                <button
                  className="text-secondarycolor "
                  onClick={() => {
                    handleEdit(item.id);
                  }}
                >
                  <span class="material-symbols-outlined text-2xl">edit</span>
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
      <RoleForm
        editRole={editRole}
        editingRole={editingRole}
        open={open}
        setOpen={setOpen}
      />
      {/* DELETE */}
      <CheckDelete
        remove={remove}
        setRemove={setRemove}
        object="role"
        elementId={elementId}
        elementName={elementName}
        removeFun={removeRole}
        setElementId={setElementId}
        submitLoader={submitLoader}
        setSubmitLoader={setSubmitLoader}
        error={error}
        setError={setError}
      />
    </table>
  );
}
