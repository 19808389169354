import React, { useState } from 'react'

export default function WorkflowCard({data, search}) {

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

  return (
    <div className='dataset-cards'>
        {
            data.reverse().map((item, i)=>{
                    return item.name.toLowerCase().indexOf(search.toLowerCase()) > -1 && 
                        <div className="card" key={i}>
                            <div className="info">
                                <span className="material-symbols-outlined">timer</span>
                                <div>
                                    <p>{item.name}</p>
                                    <h5>{item.description ? item.description : "---"}</h5>
                                </div>
                            </div>
                            <div className="more">
                                <div>
                                    <span className="material-symbols-outlined">schedule</span>
                                    <p>{item.connector ? item.connector.name : "---"}</p>
                                </div>
                                <div>
                                    <span className="material-symbols-outlined">date_range</span>
                                    <p>{formatDate(item.created_at)}</p>
                                </div>
                            </div>
                        </div>
            })
        }
    </div>
  )
}
