import React, { useEffect, useState } from "react";
import CheckDelete from "../pop/CheckDelete";
import { Link, useParams } from "react-router-dom";
import { getRuns } from "../../../api/LogApi";
import { useQueryClient } from "@tanstack/react-query";

export default function EtlRunTable({
  etlRuns,
  removeFun,
  elementId,
  setElementId,
  search,
}) {
  const [remove, setRemove] = useState(false);
  const [runData, setRunData] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  useEffect(() => {
    const fetchRunData = async (idRun) => {
      const result = await queryClient.fetchQuery(["getRun", idRun], () =>
        getRuns(idRun)
      );
      setRunData((prevData) => ({ ...prevData, [idRun]: result }));
    };

    etlRuns.forEach((item) => {
      const idRun = item.id_run;
      fetchRunData(idRun);
    });
  }, [etlRuns, queryClient]);

  const findStatus = (id) => {
    const run = runData[id];
    return run ? run.state : "NOTHING";
  };

  const handleDelete = (id) => {
    setElementId(id);
    setRemove(true);
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Run id</th>
          <th>Start Date</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {etlRuns
          ? etlRuns.map((item, i) => {
              return (
                item.id_run
                  .toString()
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) > -1 &&
                item.id_etl == id && (
                  <tr key={i}>
                    <td data-label="Run id">
                      <div className="main">
                        <Link to={`/user/run/${item.id_run}`}>
                          <span className="material-symbols-outlined">
                            play_arrow
                          </span>
                          <p>#{item.id_run}</p>
                        </Link>
                      </div>
                    </td>
                    <td data-label="Start Date">{item.start_date}</td>
                    <td data-label="Status">
                      <span
                        className={
                          findStatus(item.id_run) == ""
                            ? item.metrics
                            : findStatus(item.id_run)
                        }
                      >
                        {findStatus(item.id_run) == ""
                          ? item.metrics
                          : findStatus(item.id_run)}
                      </span>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          handleDelete(item.id_run);
                        }}
                      >
                        <span className="material-symbols-outlined">
                          Delete
                        </span>
                      </button>
                    </td>
                  </tr>
                )
              );
            })
          : "loading"}
      </tbody>

      {/* DELETE */}
      <CheckDelete
        remove={remove}
        setRemove={setRemove}
        object="ETL Run"
        elementId={elementId}
        removeFun={removeFun}
        setElementId={setElementId}
      />
    </table>
  );
}
