import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getAllEtls } from "../../../api/EtlApi.js";
import { Cron } from "react-js-cron";
import "react-js-cron/dist/styles.css";
export default function WorkflowForm({
  reason,
  setReason,
  getForm,
  setForm,
  addFun,
  updateFun,
  elementId,
  allWorkflows,
  setSubmitLoader,
}) {
  // ETLS INFORMATION
  const getEtls = useQuery(["getConnectors"], () => getAllEtls(1, 9999, ""));
  const [value, setValue] = useState("*/2 * * * *");

  // CHECK FIELDS
  const [requiredField, setRequiredField] = useState(false);

  useEffect(() => {
    // SET FORM
    if (reason === "update") {
      let workflow = allWorkflows?.filter(
        (item) => item.id_workflow === elementId
      );
      setIdWorkflow(workflow[0].id_workflow);
      name.current.value = workflow[0].name;
      description.current.value = workflow[0].description;
      id_etl.current.value = workflow[0].id_etl;
      setValue(workflow[0].cron);
    }
  }, [elementId, getForm, setReason]);

  // Payload
  const [id_workflow, setIdWorkflow] = useState(null);
  const name = useRef();
  const description = useRef();
  const [cron, setCron] = useState("* * * * * *");
  const id_etl = useRef();

  // HANDLE FORM
  function handleForm() {
    // CHECK VALUES
    if (name.current.value.length && id_etl.current.value.length) {
      const data = {
        id_workflow: id_workflow,
        name: name.current.value,
        description: description.current.value,
        cron: value,
        id_etl: id_etl.current.value,
      };
      // CREATE Workflow || UPDATE Workflow
      reason === "create" ? addFun.mutate(data) : updateFun.mutate(data);
      handleControllForm();
      setSubmitLoader(true);
    } else {
      setRequiredField(true);
    }
  }

  // HANDLE OPEN & CLOSE FORM
  const handleControllForm = () => {
    setForm(false);
    setReason("create");
    setValue("* * * * * *");
    setRequiredField(false);
    name.current.value = "";
    description.current.value = "";
    setCron("* * * * * *");
    id_etl.current.value = "";
  };

  return (
    <>
      {/* BG CONTENT */}
      <div
        className="bg-form"
        style={{ display: getForm ? "block" : "none" }}
      />
      {/* CONTENT */}
      <section
        className="connector-form"
        style={{
          transform: getForm
            ? "translate(-50%, 0) scale(1)"
            : "translate(-50%, 0) scale(0)",
        }}
      >
        {/* HEAD */}
        <div className="head">
          <h3>{reason === "create" ? "New" : "Update"} Wrokflow</h3>
          <button
            onClick={() => {
              setForm(!getForm);
            }}
          >
            <span
              className="material-symbols-outlined"
              onClick={handleControllForm}
            >
              close
            </span>
          </button>
        </div>
        {/* FORM */}
        <div className="form">
          <div className="inps">
            <div className="inp">
              <label>Name *</label>
              <input
                type="text"
                placeholder="Enter workflow's name"
                ref={name}
              />
              <div className="required-error">
                {requiredField && name.current.value === ""
                  ? "Oops! Name field is required."
                  : ""}
              </div>
            </div>

            <div className="inp">
              <label>Etl *</label>
              <select ref={id_etl}>
                <option value="">--- Select Etl ---</option>
                {getEtls.isLoading ? (
                  <p>Loading...</p>
                ) : (
                  getEtls.data.data.map((etl) => {
                    return <option value={etl.id_etl}>{etl.name}</option>;
                  })
                )}
              </select>
              <div className="required-error">
                {requiredField && id_etl.current.value === ""
                  ? "Oops! Etl field is required."
                  : ""}
              </div>
            </div>
          </div>

          <div className="inp">
            <label>Schedule *</label>
          </div>
          <div>
            <h3>{value}</h3>
          </div>
          <div>
            <Cron value={value} setValue={setValue} />
          </div>
          <div className="inp">
            <label>Description</label>
            <textarea
              cols="30"
              rows="10"
              placeholder="Add description"
              ref={description}
            ></textarea>
          </div>

          <div className="submit">
            <button onClick={handleControllForm}>Cancel</button>
            <button onClick={handleForm}>
              {reason === "create" ? "Create" : "Update"} Create
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
