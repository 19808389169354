import React, { useEffect } from "react";

const Pagination = ({ data, page, setPage }) => {
  const fetchNextPrevTasks = (link) => {
    if (link) {
      const url = new URL(link);
      setPage(+url.searchParams.get("page"));
    }
  };
  const renderPaginationLinks = () => {
    return (
      <div className="flex  items-center justify-between border-t border-gray-200  px-4 py-3 sm:px-6">
        <button
          onClick={() => fetchNextPrevTasks(data?.links[0].url)}
          className="relative bg-white inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        >
          <span className="sr-only">Previous</span>
          <svg
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        {data?.links.slice(1, -1).map((link, index) => (
          <button
            key={index}
            onClick={() => fetchNextPrevTasks(link.url)}
            className={`relative hidden items-center px-4 py-2 text-sm font-semibold ${
              link.active ? "bg-[#1A66FF]" : "bg-white"
            }  text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex`}
          >
            {link.label}
          </button>
        ))}
        <button
          onClick={() =>
            fetchNextPrevTasks(data?.links[data?.links.length - 1].url)
          }
          className="relative inline-flex items-center bg-white rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        >
          <span className="sr-only">Next</span>
          <svg
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    );
  };
  return (
    <div className="flex justify-between mt-5 items-center">
      {data?.from ? (
        <div>
          Showing {data?.from} to {data?.to} from {data?.total} results.
        </div>
      ) : (
        <div></div>
      )}
      {renderPaginationLinks()}
    </div>
  );
};

export default Pagination;
