import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../context/ContextProvider";
import axios from "axios";
import { API_URL, TOKEN } from "../../../conf.js";
import { Backdrop, CircularProgress } from "@mui/material";
export default function ConnectorForm({
  reason,
  setReason,
  getForm,
  setForm,
  addFun,
  updateFun,
  elementId,
  allConnectors,
  setSubmitLoader,
}) {
  // USER INFO
  const [types, setTypes] = useState([
    { name: "S3", url: "http://host:port" },
    { name: "Oracle", url: "jdbc:oracle:thin:@//host:port/database_name" },
    { name: "MySQL", url: "jdbc:mysql://host:port/database_name" },
    { name: "PostgreSQL", url: "jdbc:postgresql://host:port/database_name" },
    {
      name: "SQL Server",
      url: "jdbc:sqlserver://host:port;databaseName=database_name;encrypt=true;trustServerCertificate=true",
    },
    { name: "API", url: "http://host:port/myapi" },
    { name: "MongoDB", url: "mongodb://host:port/database_name.collection" },
  ]);

  // CHANGE TYPE STYLE
  const [dbSource, setDbSource] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // FORM VALUES
  const [idConnector, setIdConnector] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [urlValue, setUrlValue] = useState("");
  const [userInfoValue, setUserInfoValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [dbError, setDbError] = useState("");
  // databse test connection

  const handleDatabaseCheck = async () => {
    try {
      setIsLoading(true);
      // Make API request to check the database connection
      const response = await axios.post(
        `${API_URL}api/dbcheck`,
        {
          db_type: dbSource.name.toLowerCase(),
          db_host: urlValue,

          db_name: name,
          db_username: userInfoValue,
          db_password: passwordValue,
        },
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      );
      setIsLoading(false);
      return true;
      // Proceed with ETL logic or show success message
    } catch (error) {
      // Handle the error and display it to the user
      setDbError("Database connection failed. Check your credentials.");
      setIsLoading(false);

      return false;
      // Update your state or show a modal with the error message
    }
  };
  // CHECK FIELDS
  const [requiredField, setRequiredField] = useState(false);

  useEffect(() => {
    // SET FORM
    if (reason === "update") {
      let connector = allConnectors.find(
        (item) => item.id_connector === elementId
      );
      const connectionOptions = connector.connection_options;

      setDbSource({
        name: connector.type,
      });

      setIdConnector(connector.id_connector);
      setName(connector.name);
      setDescription(connector.description);
      setUrlValue(
        connectionOptions.url
          ? connectionOptions.url
          : connectionOptions.endpoint
      );
      setUserInfoValue(
        connectionOptions.user
          ? connectionOptions.user
          : connectionOptions.access_key
      );
      setPasswordValue(
        connectionOptions.password
          ? connectionOptions.password
          : connectionOptions.secret_key
      );
    }
  }, [elementId, getForm, allConnectors, reason]);

  async function handleForm() {
    // CHECK VALUES
    if (name && urlValue) {
      let connectionOptions = {};
      const isDatabaseConnected = await handleDatabaseCheck();
      if (isDatabaseConnected) {
        if (dbSource.name === "S3") {
          connectionOptions = {
            endpoint: urlValue,
            access_key: userInfoValue,
            secret_key: passwordValue,
          };
        } else if (dbSource.name === "API") {
          connectionOptions = {
            url: urlValue,
            user: userInfoValue,
            password: passwordValue,
          };
        } else {
          connectionOptions = {
            url: urlValue,
            user: userInfoValue,
            password: passwordValue,
          };
        }

        const data = {
          name,
          description,
          type: dbSource.name,
          connection_options: connectionOptions,
          id_connector: idConnector,
        };

        // CREATE CONNECTOR || UPDATE CONNECTOR
        reason === "create" ? addFun.mutate(data) : updateFun.mutate(data);
        handleControllForm();
        setSubmitLoader(true);
      }
    } else {
      setRequiredField(true);
    }
  }

  // HANDLE OPEN & CLOSE FORM
  const handleControllForm = () => {
    setDbError("");
    setForm(false);
    setRequiredField(false);
    setReason("create");

    if (dbSource !== "") {
      setName("");
      setDescription("");
      setUrlValue("");
      setUserInfoValue("");
      setPasswordValue("");
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* BG CONTENT */}
      <div
        className="bg-form"
        style={{ display: getForm ? "block" : "none" }}
      ></div>

      {/* CONTENT */}
      <section
        className="connector-form"
        style={{
          transform: getForm
            ? "translate(-50%, 0) scale(1)"
            : "translate(-50%, 0) scale(0)",
        }}
      >
        {/* HEAD */}
        <div className="head">
          <div>
            <h3>{reason === "create" ? "New" : "Update"} Connector</h3>
          </div>
          <button onClick={handleControllForm}>
            <span className="material-symbols-outlined">close</span>
          </button>
        </div>

        {/* SELECT TYPE */}
        <div className="w-full flex justify-center items-center transition-all bg-gray-200 p-1 py-2 mt-2 rounded-2xl">
          <select
            onChange={(e) => {
              const selectedType = types.find(
                (type) => type.name === e.target.value
              );
              setDbSource(selectedType);
            }}
            value={dbSource.name}
            className="w-full bg-gray-200 text-gray-500 text-center text-lg rounded-2xl"
          >
            <option value="" hidden>
              Connector type
            </option>
            {types.map((type) => (
              <option key={type.name} value={type.name}>
                {type.name}
              </option>
            ))}
          </select>
        </div>

        {/* FORM */}
        {dbSource ? (
          <div className="form">
            <div className="inps">
              <div className="inp">
                <label>Name *</label>
                <input
                  type="text"
                  placeholder="Enter database's name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <div className="required-error">
                  {requiredField && name === ""
                    ? "Oops! Name field is required."
                    : ""}
                </div>
              </div>
              <div className="inp">
                <label>{dbSource.name === "S3" ? "Endpoint" : "Url"} *</label>
                <input
                  type="text"
                  placeholder={dbSource.url}
                  value={urlValue}
                  onChange={(e) => setUrlValue(e.target.value)}
                />
                <div className="required-error">
                  {requiredField && urlValue === ""
                    ? `Oops! ${
                        dbSource.name === "S3" ? "Endpoint" : "Url"
                      } field is required.`
                    : ""}
                </div>
              </div>

              <div className="inp">
                <label>
                  {dbSource.name === "S3"
                    ? "Access Key"
                    : dbSource.name === "API"
                    ? "User"
                    : "User *"}
                </label>
                <input
                  type="text"
                  placeholder={`Enter the ${
                    dbSource.name === "S3" ? "access key" : "user"
                  }`}
                  value={userInfoValue}
                  onChange={(e) => setUserInfoValue(e.target.value)}
                />
                <div className="required-error">
                  {requiredField &&
                  userInfoValue === "" &&
                  dbSource.name !== "API"
                    ? `Oops! ${
                        dbSource.name === "S3" ? "Access Key" : "User"
                      } field is required.`
                    : ""}
                </div>
              </div>

              <div className="inp">
                <label>
                  {dbSource.name === "S3" ? "Secret Key" : "Password"}
                </label>
                <input
                  type="password"
                  placeholder={`Enter the ${
                    dbSource.name === "S3" ? "secret key" : "password"
                  }`}
                  value={passwordValue}
                  onChange={(e) => setPasswordValue(e.target.value)}
                />
                <div className="required-error"></div>
              </div>
            </div>
            <div className="inp">
              <label>Description</label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Add description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
              <div className="required-error"></div>
            </div>
            <div className="-mt-5 mb-5">
              <p className="text-red-500">{dbError}</p>
            </div>
            <div className="submit">
              <button onClick={handleControllForm}>Cancel</button>
              <button onClick={handleForm}>
                {reason === "create" ? "Create" : "Update"}
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    </>
  );
}
