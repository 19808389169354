import React, { useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getOneEtlRun } from "../api/EtlRunApi";
import { getAllLogs, getRuns } from "../api/LogApi";
import { getOneEtl } from "../api/EtlApi";

export default function Run() {
  const queryClient = useQueryClient();

  // ETL RUN ID
  const { id } = useParams();

  // ETL RUN INFORMATIONS
  const runs = useQuery(["getRuns"], () => getRuns(id));

  // LOG DATA
  const savedLogs = useQuery(["SavedLogs"], () => getOneEtlRun(id), {
    onSuccess: (data) => {
      etl.mutate(data.id_etl);
    },
  });
  const originLogs = useQuery(["OriginLogs"], () => getAllLogs(id));

  // Find Status
  const findStatus = (id) => {
    return runs ? runs.data.state : savedLogs.data.metrics;
  };

  // Etl DATA
  const etl = useMutation(getOneEtl, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["etl"]);
    },
  });

  const logLines = useRef();

  useEffect(() => {
    if (logLines.current) {
      logLines.current.scrollTop = logLines.current.scrollHeight;
    }
  }, [originLogs, savedLogs]);

  if (savedLogs.isLoading || originLogs.isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="connector-form run-log run">
      {/* HEAD */}
      <div className="head">
        <h3>
          <Link to={`/user/etl`} className="text-black underline">
            Etl&nbsp;
          </Link>
          &#62;&nbsp;
          <Link
            to={`/user/etl_run/${savedLogs.data.id_etl}`}
            className="text-black underline"
          >
            {savedLogs.data ? savedLogs.data.etl.name : "loading"}&nbsp;
          </Link>
          &#62;&nbsp;{id}
        </h3>
        <Link to={`/user/etl_run/${savedLogs.data.id_etl}`} className="back">
          <span class="material-symbols-outlined">arrow_back_ios</span>
          <p>Back</p>
        </Link>
      </div>

      {/* LOG */}
      <div className="log">
        {/* INFO */}
        <div className="infos">
          <div className="info">
            <div>
              <span class="material-symbols-outlined">line_start_circle</span>
              <h5>Run Id</h5>
            </div>
            <p>{id}</p>
          </div>
          <div className="info">
            <div>
              <span class="material-symbols-outlined">tenancy</span>
              <h5>Etl Name</h5>
            </div>
            <p>{savedLogs.data ? savedLogs.data.etl.name : "loading"}</p>
          </div>
          <div className="info">
            <div>
              <span class="material-symbols-outlined">stat_0</span>
              <h5>Status</h5>
            </div>
            <p className="status">
              {(findStatus(savedLogs.data.id_run) === "running" ||
                findStatus(savedLogs.data.id_run) === "starting") && (
                <span class="material-symbols-outlined">progress_activity</span>
              )}
              {findStatus(savedLogs.data.id_run)}
            </p>
          </div>
          <div className="info">
            <div>
              <span class="material-symbols-outlined">schedule</span>
              <h5>Start Date</h5>
            </div>
            <p>{savedLogs.data.start_date}</p>
          </div>
          <div className="info">
            <div>
              <span class="material-symbols-outlined">schedule</span>
              <h5>End Date</h5>
            </div>
            <p>{savedLogs.data.end_date}</p>
          </div>
        </div>

        <hr />

        <div className="log-lines" ref={logLines}>
          {originLogs.data.log
            ? originLogs.data.log.map((item, i) => {
                return <p dangerouslySetInnerHTML={{ __html: item }} />;
              })
            : savedLogs.data.logs.split(",").map((item, i) => {
                return <p dangerouslySetInnerHTML={{ __html: item }} />;
              })}
        </div>
      </div>
    </div>
  );
}
