import { Checkbox } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useEtlContext } from '../../../context/EtlProvider';
import SuccessMsg from '../../dashboard/pop/SuccessMsg';

export default function TransformSettings({controll, setControll, nodeId}) {
    
    // POP MESSGAE
    const [msg, setMessage] = useState({ display: false, theme: '', name: '' })

    // CASH STATUS
    const [cash, setCash] = useState(true);

    const [requiredField, setRequiredFild] = useState(false)

    // ETL CONTEXT
    const { etlData, setEtlData} = useEtlContext()

    // FORM VALUES
    const sqlText= useRef()
    const [formStatus, setFormStatus] = useState('create')

    useEffect(() => {
        // EXIST FLOW
        const node = etlData.transformers.find(item=>{ return item.id == nodeId })
        if(node){
            setFormStatus('update')
            sqlText.current.value = node.select_expression
        }else{
            setFormStatus('create')
            sqlText.current.value = ''
        }
    }, [nodeId, controll])

    // SAVE CONFIG
    const saveConfig = () => {
        if(sqlText.current.value != ''){
            // CREATE A NEW TRANSFORMER
            const newTransformer = {
                id : nodeId,
                select_expression : sqlText.current.value,
                cached : cash
            }
            // ADD TO ETL
            if(formStatus == 'create'){
                setEtlData({
                    sources: etlData == undefined ? [] : [...etlData.sources],
                    transformers: etlData == undefined ? [newTransformer] : [...etlData.transformers, newTransformer],
                    outputs: etlData == undefined ? [] : [...etlData.outputs]
                })
            }else{
                const nodeUpdated = etlData.transformers.filter(item=>{ // all transformers
                    return item.id != nodeId;
                })
                setEtlData({
                    sources: etlData == undefined ? [] : [...etlData.sources],
                    transformers: etlData == undefined ? [newTransformer] : [...nodeUpdated, newTransformer],
                    outputs: etlData == undefined ? [] : [...etlData.outputs]
                })
            }
            // SAVE MESSAGE
            setMessage({ display: true, theme: 'save', name: 'Transform' })
            setTimeout(() => {
                setMessage({ display: false, theme: '', name: '' })
            }, 3000);
            // CLOSE CONFIG 
            closeConfig()
        }else{
            setRequiredFild(true)
        }
    }

    // CLOSE CONFIG
    const closeConfig = () => {
        setControll(!controll)
        setRequiredFild(false)
        // CLEAR FORM
        sqlText.current.value = ''
    }

  return (
    <>
        {/* POP MESSGAE */}
        <SuccessMsg display={msg.display} theme={msg.theme} name={msg.name} />
        {/* CONFIG */}
        <div className="bg" style={{display: controll ? 'block' : 'none'}}></div>
        <div className={controll ? 'settings source-set open' : 'settings source-set'}>
            {/* HEAD */}
            <div className="head">
                <button onClick={()=>{ closeConfig() }}>
                    <span className="material-symbols-outlined">arrow_back_ios_new</span>
                </button>
                <h3>Data Transformer</h3>
                <span></span>
            </div>
            {/* FORM */}
            <div className="setting-content">
                <div className="inps">
                    <div className="inp">
                        <label>Transfromer id :</label>
                        <input type="text" value={nodeId} disabled={true} />
                    </div>
                    <div className="inp">
                        <label>SQL Query :</label>
                        <textarea 
                            className='sql-source' name="" id="" cols="30" rows="10"ref={sqlText} >
                        </textarea>
                        <div className='required-error'>
                            {requiredField && sqlText.current.value == '' ? 'Oops! SQL Query field is required.' : ''}
                        </div>
                    </div>
                    <div className="inp-box">
                        <Checkbox
                            checked={cash}
                            onChange={(e)=>{ setCash(e.target.checked) }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <p>Cached</p>
                    </div>
                </div>
                <div className="submit">
                    <button onClick={()=>{ closeConfig() }}>Cancel</button>
                    <button onClick={()=>{ saveConfig() }}>{formStatus == 'create' ? 'Save' : 'Update'}</button>
                </div>
            </div>
        </div>
    </>
  )
}
