import React, { useEffect, useRef, useState } from "react";
import { useStateContext } from "../../../context/ContextProvider";

export default function EtlForm({
  reason,
  setReason,
  getForm,
  setForm,
  addFun,
  updateFun,
  elementId,
  allEtls,
  setSubmitLoader,
  submitLoader,
}) {
  // USER INFO

  // FORM VALUES
  const [id_etl, set_id_etl] = useState(null);
  const name = useRef();
  const description = useRef();

  useEffect(() => {
    // SET FORM
    if (reason == "update") {
      let etl = allEtls.filter((item) => item.id_etl == elementId);
      set_id_etl(etl[0].id_etl);
      name.current.value = etl[0].name;
      description.current.value = etl[0].description;
    }
  }, [elementId, getForm, setReason]);

  // HANDLE OPEN & CLOSE FORM
  const handleControllForm = () => {
    setForm(false);
    setReason("create");
    setRequiredField(false);
    set_id_etl(null);
    name.current.value = "";
    description.current.value = "";
  };
  // HANDLE FORM
  function handleForm() {
    // CHECK VALUES
    if (name.current.value.length > 0) {
      const data = {
        name: name.current.value,
        description: description.current.value,
        id_etl: id_etl,
        json_plan: null,
        stat: null,
        code: null,
      };
      // CREATE ETL || UPDATE ETL
      reason == "create" ? addFun.mutate(data) : updateFun.mutate(data);
      handleControllForm();
      setSubmitLoader(true);
    } else {
      setRequiredField(true);
    }
  }

  // CHECK FIELDS
  const [requiredField, setRequiredField] = useState(false);

  return (
    <>
      {/* BG CONTENT */}
      <div
        className="bg-form"
        style={{ display: getForm ? "block" : "none" }}
      ></div>
      {/* CONTENT */}
      <section
        className="connector-form"
        style={{
          transform: getForm
            ? "translate(-50%, 0) scale(1)"
            : "translate(-50%, 0) scale(0)",
        }}
      >
        {/* HEAD */}
        <div className="head">
          <h3>New ETL</h3>
          <button
            onClick={() => {
              handleControllForm();
            }}
          >
            <span className="material-symbols-outlined">close</span>
          </button>
        </div>
        {/* FORM */}
        <div className="form">
          <div className="inp">
            <label>Name *</label>
            <input type="text" placeholder="Enter ETL name" ref={name} />
            <div className="required-error">
              {requiredField && name.current.value == ""
                ? "Oops! Name field is required."
                : ""}
            </div>
          </div>
          <div className="inp">
            <label>Description</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              placeholder="Add description"
              ref={description}
            ></textarea>
            <div className="required-error"></div>
          </div>
          <div className="submit">
            <button
              onClick={() => {
                handleControllForm();
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleForm();
              }}
            >
              Create
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
