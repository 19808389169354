import React, { useEffect, useState } from "react";
import EtlRunTable from "../components/dashboard/tables/EtlRunTable";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import SuccessMsg from "../components/dashboard/pop/SuccessMsg";
import { deleteEtlRun, getAllEtlRuns } from "../api/EtlRunApi";
import { getRuns } from "../api/LogApi";
import { Backdrop, CircularProgress } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import Pagination from "../components/Pagination";
import { getOneEtl } from "../api/EtlApi";

export default function EtlRun() {
  // INOF STYLE
  const [infoStyle, setInfoStyle] = useState("table");
  const [search, setSearch] = useState("");

  // POP MESSGAE
  const [msg, setMessage] = useState({ display: false, theme: "", name: "" });
  const [page, setPage] = useState(1);

  const { id } = useParams();
  // GET FORM
  const queryClient = useQueryClient();
  const [elementId, setElementId] = useState(null);

  // ETL RUN INFORMATIONS
  const getEtlRuns = useQuery(["getEtlRuns", page], () =>
    getAllEtlRuns(id, page, 5)
  );
  const getEtl = useQuery(["getEtl", id], () => getOneEtl(id));

  // DELETE ETL RUN
  const removeEtl = useMutation(deleteEtlRun, {
    onSuccess: () => {
      queryClient.invalidateQueries(["etl"]);
      getEtlRuns.refetch();
      setMessage({ display: true, theme: "delete", name: "Etl Run" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
  });
  // useEffect(() => {}, [getEtlRuns.data]);
  if (getEtlRuns.isLoading || getRuns.isLoading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 99 }}
        open="true"
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <div className="etl">
      <SuccessMsg display={msg.display} theme={msg.theme} name={msg.name} />

      {/* TOP */}
      <div className="top">
        <h3>
          <Link to={`/user/etl`} className="text-black underline">
            Etl
          </Link>
          &#62;
          {getEtl.data?.name}
        </h3>
      </div>
      {/* FILTER */}
      <div className="filter-style">
        <div className="filter">
          <span class="material-symbols-outlined">search</span>
          <input
            type="text"
            placeholder="Search by ETL Run id ..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="style">
          <button
            className={infoStyle == "card" ? "grid" : ""}
            onClick={() => {
              setInfoStyle("card");
            }}
          >
            <span class="material-symbols-outlined">grid_view</span>
          </button>
          <button
            className={infoStyle == "table" ? "grid" : ""}
            onClick={() => {
              setInfoStyle("table");
            }}
          >
            <span class="material-symbols-outlined">view_agenda</span>
          </button>
        </div>
      </div>
      {/* INFORMATION */}
      <EtlRunTable
        etlRuns={getEtlRuns.data?.data}
        removeFun={removeEtl}
        search={search}
        elementId={elementId}
        setElementId={setElementId}
      />
      <Pagination data={getEtlRuns.data} setPage={setPage} />
    </div>
  );
}
