import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useStateContext } from '../../../context/ContextProvider'

export default function Header() {

  const { user } = useStateContext()

  const [titles, setTitles] = useState([
    { title: `Welcome, ${user.username}! `, subtitle: "Admin Dashboard Digest: Today's Highlights.", page: "/admin"},
    { title: "User Information", subtitle: "View and Manage User Details.", page: "/admin/users"},
    { title: "Playground", subtitle: " Experiment and Explore with No Limits.", page: "/admin/playground"},
  ])

  const {pathname} = useLocation()

  return (
    <header>
        {/* HELLO */}
        {
          titles.map(item=>{
             return item.page == pathname && 
              <div className="hello">
                  <h3>{item.title}</h3>
                  <p>{item.subtitle}</p>
              </div>
          })
        }
        
        {/* OPTIONS */}
        <div className="options">
            {/* SEARCH */}
            <div className="search">
                <span className="material-symbols-outlined">search</span>
                <input type="text" placeholder='Search a user..' />
                <button>
                  <span className="material-symbols-outlined">auto_awesome</span>
                  <p>Search</p>
                </button>
            </div>
            {/* SETTINGS */}
            <button className='settings'><span className="material-symbols-outlined">settings</span></button>
        </div>
    </header>
  )
}
