import React, { useEffect, useRef, useState } from 'react'
import { Handle, Position } from 'reactflow'
import { useEtlContext } from '../../../context/EtlProvider';

export default function TransformNode({data, id}) {

  // ACTIONS CONTROLL
  const [action, setAction] = useState(false)

  const actionsRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        setAction(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [actionsRef]);

  // NODE INFO
  const { etlData } = useEtlContext()

  // GET NODE NAME
  const [nodeName, setName] = useState('')
  useEffect(() => {
    if(etlData){
      let node = etlData.transformers.find(item=>{
        return item.id == id
      })
      // node && setName(node.name)      
    }
  }, [etlData])
  

  return (
    <div className='node transform'>
        {/* HANDLE */}
        <Handle type="source" position={Position.Left} />
        <Handle type="target" position={Position.Right} />
        {/* TEXT */}
        <div className="text">
          <span className="material-symbols-outlined">pentagon</span>
          <div className="info">
              <p>Transform</p>
              {/* <h5>{nodeName != '' ? nodeName : "Transform Data"}</h5> */}
              <h5>{id}</h5>
          </div>
        </div>
        {/* ACTION BUTTON */}
        <button onClick={()=>{ setAction(!action) }}>
            <span className="material-symbols-outlined">more_horiz</span>
        </button>
        {/* ACTIONS */}
        <div className="actions" ref={actionsRef} style={{display: action ? 'block' : 'none'}}>
            <div className="act" onClick={()=>{ data.showSetting(id, 'transform') }}>
                <span className="material-symbols-outlined">settings</span>
                <p>Setting</p>
            </div>
            <div className="act" onClick={()=>{ data.remove(id) }}>
                <span className="material-symbols-outlined">delete</span>
                <p>Delete</p>
            </div>
        </div>
    </div>
  )
}
