import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {createContext, useContext, useEffect, useState} from 'react'
import { getAllEtls, getEtlJsonPlan, putEtl } from '../api/EtlApi'

const StateContext = createContext({
    etlData: {
        sources: [],
        transformers: [],
        outputs: []
    },
    setEtlData: () => {},
    etlId : null,
    setEtlId: () => {}
})

export const EtlProvider = ({children}) => {

  // >>>>>>>>>>> ETL <<<<<<<<<<<
  const [etlData, setEtlData] = useState() 
  const [etlId, setEtlId] = useState() 

  // ETL INFORMATIONS
  const getEtls = useQuery(['getEtls'], () => getAllEtls(1,9999,"") )
  const queryClient = useQueryClient()

  // >>>>>>>>>>> SAVE ETL CONFIG (UPDATE CONFIG DATA IN DATABASE) <<<<<<<<<<<
  const saveConfig = useMutation(putEtl, {
    onSuccess: () => {
        queryClient.invalidateQueries(["etl"]);
        getEtls.refetch()
       
    }
  });

  const getConfig = useMutation(getEtlJsonPlan, {
    onSuccess: (data) => {
        queryClient.invalidateQueries(["json_plan"]);
        const plan = {
            sources: data.datasets,
            transformers: data.dag.transformers,
            outputs: data.dag.outputs
        }
        setEtlData(plan) 
    }
  });

  useEffect(() => {
      // EXIST ETL CONFIG
      etlId && getConfig.mutate(etlId)
  }, [etlId])

  // UDATE ETL DATA
  useEffect(() => {
      if(etlId && etlData){
        // INPUTS
        const inputs = etlData.sources.filter(item=>{ return item.id.slice(0,1) == "i" })
        const plan = {
            datasets : etlData.sources,
            dag : {
                input_dataset_ids : inputs.map(item=>{ return item.id }),  // old -v (dataset_id)
                transformers : etlData.transformers,
                outputs : etlData.outputs
            }
        } 
          const newData = {id_etl: etlId, json_plan: plan}
          saveConfig.mutate(newData)
      }
  }, [etlData, etlId])

  
  
  return (
        <StateContext.Provider value={{etlData, setEtlData, etlId, setEtlId}}>
            {children}
        </StateContext.Provider>
  )
}


export const useEtlContext = () => useContext(StateContext)

