import React from 'react'

export default function ErrorMsg({display, text}) {
  return (
    <div className={display ? 'error-msg success-msg' : 'error-msg'}>
        <div className="text">
            <span className="material-symbols-outlined">warning</span>
            <div>
                <p>Uh oh, something went wrong</p>
                <h5>Sorry! {text}</h5>
            </div>
        </div>
        <button>
            <span className="material-symbols-outlined">close</span>
        </button>
    </div>
  )
}
