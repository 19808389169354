import { Checkbox } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import { getAllDatasets } from "../../../api/DatasetsApi";
import { useQuery } from "@tanstack/react-query";
import { useEtlContext } from "../../../context/EtlProvider";
import SuccessMsg from "../../dashboard/pop/SuccessMsg";
import { getAllConnectors } from "../../../api/ConnectorsApi";
import { API_URL, TOKEN } from "../../../conf";
import axios from "axios";

export default function SourceSettings({
  controll,
  setControll,
  nodeId,
  updateNodeLabel,
}) {
  const [msg, setMessage] = useState({ display: false, theme: "", name: "" });
  const [cash, setCash] = useState(true);
  const { etlData, setEtlData } = useEtlContext();
  const [sourceName, setName] = useState("");
  const [dataset, setDataset] = useState("");
  const [path, setPath] = useState("");
  const [requiredField, setRequiredField] = useState(false);
  const [tables, setTables] = useState([]);
  const [error, setError] = useState("");
  const [tablesLoading, setTablesLoading] = useState(false);
  const [formStatus, setFormStatus] = useState("create");

  const getDatasets = useQuery(["getDatasets"], () =>
    getAllDatasets(1, 99999, "")
  );
  const getConnectors = useQuery(["getConnectors"], () =>
    getAllConnectors(1, 99999, "")
  );

  const fetchSchema = useCallback(
    async (dbType, url, user, password, table) => {
      try {
        setTablesLoading(true);
        const response = await axios.post(
          `${API_URL}api/fetchStructure`,
          {
            db_type: dbType,
            url: url,
            user: user,
            password: password,
            table: table,
          },
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
          }
        );

        setTables(response.data.structure);
      } catch (error) {
        setTables([]);
        setError("Connector offline");
      } finally {
        setTablesLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (dataset) {
      const dataFetch = getDatasets?.data?.data?.find(
        (item) => item.id_dataset == dataset
      );
      updateNodeLabel(nodeId, dataFetch?.name);
      console.log(dataFetch);
      if (dataFetch) {
        fetchSchema(
          dataFetch.connector.type,
          dataFetch.connector.connection_options.url,
          dataFetch.connector.connection_options.user,
          dataFetch.connector.connection_options.password,
          dataFetch.dataset_options.table
        );
      }
      setTables([]);
    }
  }, [dataset]);

  const getExtension = (file) => {
    const parts = file.split(".");
    return parts.length > 1 ? parts.pop() : "No extension found";
  };

  useEffect(() => {
    const node = etlData.sources.find((item) => item.id == nodeId);
    if (node) {
      setFormStatus("update");
      setDataset(node.dataset_id);
      setPath(node.path);
    } else {
      setFormStatus("create");
      setName("");
      setDataset("");
      setPath("");
    }
  }, [nodeId, controll, etlData.sources]);

  const saveConfig = () => {
    if (dataset) {
      const datasetInfo = getDatasets.data.data.find(
        (item) => item.id_dataset == dataset
      );
      const connectorInfo = getConnectors.data.data.find(
        (item) => item.id_connector == datasetInfo.id_connector
      );

      let connectorOptionsCustom = {};
      if (connectorInfo.type === "S3") {
        connectorOptionsCustom = {
          endpoint: connectorInfo.connection_options.endpoint,
          access_key: connectorInfo.connection_options.access_key,
          secret_key: connectorInfo.connection_options.secret_key,
          bucket_name: datasetInfo.dataset_options.bucket || "",
        };
      } else if (connectorInfo.type === "MongoDB") {
        connectorOptionsCustom = {
          url: connectorInfo.connection_options.url,
          user: connectorInfo.connection_options.user,
          password: connectorInfo.connection_options.password,
        };
      } else {
        connectorOptionsCustom = {
          url: connectorInfo.connection_options.url,
          user: connectorInfo.connection_options.user,
          password: connectorInfo.connection_options.password,
          schema: datasetInfo.dataset_options.schema || "",
        };
      }

      const newSource = {
        id: nodeId,
        dataset_id: dataset,
        cached: true,
        connectorOptions: connectorOptionsCustom,
        contentOptions: {},
      };

      if (["s3", "api"].includes(connectorInfo.type.toLowerCase())) {
        newSource.type = "file";
        newSource.connectorType = connectorInfo.type;
        newSource.path = datasetInfo.dataset_options.path;
        newSource.fileType = getExtension(datasetInfo.dataset_options.path);
      } else if (
        ["mysql", "oracle", "postgresql", "sql server"].includes(
          connectorInfo.type.toLowerCase()
        )
      ) {
        newSource.type = "sql";
        newSource.table = datasetInfo.dataset_options.table;
      } else if (["mongodb"].includes(connectorInfo.type.toLowerCase())) {
        newSource.type = "nosql";
        newSource.collection = datasetInfo.dataset_options.collection;
        newSource.connectorType = connectorInfo.type;
      } else {
        return;
      }

      if (formStatus === "create") {
        setEtlData((prevData) => ({
          sources: [...(prevData?.sources || []), newSource],
          transformers: [...(prevData?.transformers || [])],
          outputs: [...(prevData?.outputs || [])],
        }));
      } else {
        const updatedSources = etlData.sources.map((item) =>
          item.id === nodeId ? newSource : item
        );
        setEtlData((prevData) => ({
          sources: updatedSources,
          transformers: [...(prevData?.transformers || [])],
          outputs: [...(prevData?.outputs || [])],
        }));
      }

      setMessage({ display: true, theme: "save", name: "Source" });
      setTimeout(
        () => setMessage({ display: false, theme: "", name: "" }),
        3000
      );
      closeConfig();
    } else {
      setRequiredField(true);
    }
  };

  const closeConfig = () => {
    setControll(!controll);
    setRequiredField(false);
    setName("");
    setDataset("");
    setPath("");
  };

  return (
    <>
      <SuccessMsg display={msg.display} theme={msg.theme} name={msg.name} />
      <div
        className="bg"
        style={{ display: controll ? "block" : "none" }}
      ></div>
      <div
        className={
          controll ? "settings source-set open" : "settings source-set"
        }
      >
        <div className="head">
          <button onClick={closeConfig}>
            <span className="material-symbols-outlined">
              arrow_back_ios_new
            </span>
          </button>
          <h3>Data Source</h3>
          <span></span>
        </div>
        <div className="setting-content">
          <div className="inps">
            <div className="inp">
              <label>Source id :</label>
              <input type="text" value={nodeId} disabled={true} />
            </div>
            <div className="inp">
              <label>Select Dataset :</label>
              <select
                value={dataset}
                onChange={(e) => setDataset(e.target.value)}
              >
                <option value="">--Select Dataset--</option>
                {!getDatasets.isLoading &&
                  getDatasets.data.data.map((item, i) => (
                    <option key={i} value={item.id_dataset}>
                      {item.name}
                    </option>
                  ))}
              </select>
              <div className="required-error">
                {requiredField &&
                  !dataset &&
                  "Oops! Dataset field is required."}
              </div>
            </div>
            <div className="-mt-4 pl-4">
              <p className="font-extrabold pb-2">Structure: </p>
              <div className="text-sm font-light">
                {tables.map((table) => {
                  return (
                    <div className="font-thin">
                      {table.Field || table.COLUMN_NAME}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="inp-box">
              <Checkbox
                checked={cash}
                onChange={(e) => setCash(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />

              <p>Cached</p>
            </div>
          </div>
          <div className="submit">
            <button onClick={closeConfig}>Cancel</button>
            <button onClick={saveConfig}>
              {formStatus === "create" ? "Save" : "Update"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
