import { Button, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import CheckDelete from '../../dashboard/pop/CheckDelete';

export default function PlaygroundTable({playgounds, setForm, setReason, elementId, setElementId, removeFun}) {

    // FORMAT DATE ( 2023-06-15T12:33:03.000000Z => Jun 15, 2023 )
    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    };
    
    const {pathname} = useLocation()

    const [remove, setRemove] = useState(false)
    // REMOVE ELEMENT NAME
    const [elementName, setName] = useState('')

    // UPDATE
    const handleUpdate = (id) => {
        setElementId(id)
        setReason('update')
        setForm(true)
    }

    // DELETE
    const handleDelete = (id, name) => {
        setElementId(id)
        setRemove(true)
        setName(name)
    }

  return (
    <table className='admin-tables'>
        <thead>
            <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Type</th>
                <th>Creativity</th>
                <th>Date</th>
                <th>User Id</th>
            </tr>
        </thead>
        <tbody>
            {
                playgounds.isFetched &&
                playgounds.data.map((item,i)=>{ 
                    return  <tr>
                                <td>#{item.id_playground}</td>
                                <td>{item.name}</td>
                                <td>{item.type}</td>
                                <td>
                                    <div className={item.creativity ? "status" : "status status-down"}>
                                        <span className='material-symbols-outlined'>emoji_objects</span>
                                        <p>{item.creativity ? "Yes" : "No"}</p>
                                    </div>
                                </td>
                                <td>{formatDate(item.created_at)}</td>
                                <td>#{item.user_id}</td>
                                <td>
                                    <Tooltip title="Edit">
                                        <button className='delete' onClick={()=>{ handleUpdate(item.id_playground) }}>
                                            <span className="material-symbols-outlined">edit</span>
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <button className='delete' onClick={()=>{ handleDelete(item.id_playground, item.name) }}>
                                            <span className="material-symbols-outlined">delete</span>
                                        </button>
                                    </Tooltip>
                                </td>
                            </tr>
                })
            }
        </tbody>

        {/* DELETE */}
        <CheckDelete
            remove={remove} setRemove={setRemove}
            object='Dataset' elementId={elementId} elementName={elementName}
            removeFun={removeFun} setElementId={setElementId}
        />
    </table>
  )
}
