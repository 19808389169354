import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import SideBar from "../components/dashboard/layouts/SideBar";
import Header from "../components/dashboard/layouts/Header";
import Loader from "../components/dashboard/layouts/Loader";
import { EtlProvider } from "./EtlProvider";
import { useStateContext } from "./ContextProvider";
import axiosClient from "../api/Authentication";
import { Helmet } from "react-helmet";

export default function DashboardContext() {
  const { user, setUser, token } = useStateContext();
  const navigate = useNavigate();

  // CHECK USER (LOGIN => YES | NO)
  useEffect(() => {
    !token && navigate("/");
  }, [0]);

  useEffect(() => {
    // GET USER INFO
    axiosClient
      .get("/user")
      .then((data) => {
        // Store User Info
        setUser(data.data);
      })
      .catch((error) => {
        // User Not Logged

        localStorage.removeItem("ACCESS_TOKEN_USER");
        window.location.reload();
      });
  }, []);

  // USER INF LOADER
  if (!user) {
    return <Loader />;
  }

  return (
    <div className="dashboard">
      <Helmet>
        <title>Dahsboard | Hi Group</title>
      </Helmet>
      <SideBar />
      <div className="content">
        <Header />
        <EtlProvider>
          <Outlet />
        </EtlProvider>
      </div>
    </div>
  );
}
