import React from "react";
import Logo from "../resources/assets/images/logo.png";
import { useStateContext } from "../context/ContextProvider";

const ChatBot = () => {
  const { user } = useStateContext();

  return (
    <>
      <div className="flex h-full antialiased text-gray-800 ">
        <div className="flex flex-row h-full w-full overflow-y-hidden">
          <div className="flex flex-col flex-auto h-full items p-6">
            <div className="flex flex-col flex-auto rounded-2xl h-[65vh] bg-gray-100  p-4">
              <div className="flex flex-col h-full  overflow-x-auto mb-4">
                <div className="flex flex-col h-full">
                  <div className="flex flex-col gap-1">
                    {/* User */}
                    <div className=" p-3 rounded-lg">
                      <div className="flex  gap-2 flex-col">
                        <div className="flex items-center gap-2">
                          {user.avatar ? (
                            <div className="w-[2.5rem] h-[2.5rem] ">
                              <img
                                src={user.avatar}
                                className="w-full h-full rounded-full"
                                alt="logo"
                              />
                            </div>
                          ) : (
                            <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                              {user.username.charAt(0)}
                            </div>
                          )}
                          <p className="font-bold">{user.username}</p>
                        </div>
                        <div className="relative text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl">
                          <div>Hey How are you today?</div>
                        </div>
                      </div>
                    </div>
                    {/* Ai */}
                    <div className=" p-3 rounded-lg">
                      <div className="flex flex-col justify-center gap-2">
                        <div className="flex items-center gap-2">
                          <div className="w-[2.5rem] h-[2.5rem] ">
                            <img
                              src={Logo}
                              className="w-full h-full rounded-full"
                              alt="logo"
                            />
                          </div>
                          <p className="font-bold">Hi Ai</p>
                        </div>
                        <div class="relative text-sm  py-2 bg-white  px-4 shadow   rounded-xl">
                          <div>I'm ok what about you?</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center  self-end  h-16 rounded-xl bg-white w-full">
                <div></div>
                <div className="flex-grow ml-4">
                  <div className="relative w-full">
                    <input
                      type="text"
                      className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                    />
                  </div>
                </div>
                <div className="mx-4">
                  <button className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0">
                    <span>Send</span>
                    <span className="ml-2">
                      <svg
                        className="w-4 h-4 transform rotate-45 -mt-px"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBot;
