import axios from "axios";
import { API_URL, TOKEN } from "../conf.js";

export async function getAllDatasets(page, per_page, search) {
  const response = await axios.get(
    `${API_URL}api/datasets?page=${page}&per_page=${per_page}&search=${search}`,
    {
      headers: { Authorization: `Bearer ${TOKEN}` },
    }
  );
  return response.data.data;
}

export async function getOneDatasets(id) {
  const response = await axios.get(`${API_URL}api/datasets/${id}`, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
  return response.data.data;
}

export async function createDatasets(data) {
  return await axios.post(`${API_URL}api/datasets`, data, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}

export async function duplicateDataset(id) {
  return await axios.post(`${API_URL}api/datasets/${id}/duplicate`, null, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}

export async function updateDatasets(data) {
  const {
    name,
    description,
    id_dataset,
    dataset_options,
    id_connector,
    user_id,
  } = data;
  return await axios.put(
    `${API_URL}api/datasets/${id_dataset}`,
    {
      name,
      description,
      id_connector,
      dataset_options,
      user_id,
    },
    { headers: { Authorization: `Bearer ${TOKEN}` } }
  );
}

export async function deleteDatasets(id) {
  return await axios.delete(`${API_URL}api/datasets/${id}`, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}
