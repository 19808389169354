import React, { useEffect, useState } from "react";
import DatasetCard from "../components/dashboard/cards/DatasetCard";
import DatasetTable from "../components/dashboard/tables/DatasetTable";
import DatasetForm from "../components/dashboard/forms/DatasetForm";
import { Backdrop, CircularProgress } from "@mui/material";

import {
  createDatasets,
  deleteDatasets,
  duplicateDataset,
  getAllDatasets,
  updateDatasets,
} from "../api/DatasetsApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import SuccessMsg from "../components/dashboard/pop/SuccessMsg";
import CardLoader from "../components/dashboard/loaders/CardLoader";
import EmptyData from "../components/dashboard/empty/EmptyData";
import Layout from "../components/dashboard/layouts/Layout";
import Pagination from "../components/Pagination";

export default function Dataset() {
  // INOF STYLE
  const [infoStyle, setInfoStyle] = useState("table");
  const [search, setSearch] = useState("");

  // Loader
  const [submitLoader, setSubmitLoader] = useState(false);

  // POP MESSGAE
  const [msg, setMessage] = useState({ display: false, theme: "", name: "" });

  // GET FORM
  const [getForm, setForm] = useState(false);
  const queryClient = useQueryClient();
  const [reason, setReason] = useState("create");
  const [elementId, setElementId] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  // DATASET INFORMATIONS
  const getDatasets = useQuery(["getDatasets", page, search, perPage], () =>
    getAllDatasets(page, perPage, search)
  );
  useEffect(() => {
    if (search) {
      // If there's a search term, reset page to 1 and set perPage to 9999
      setPage(1);
      setPerPage(9999);
    } else if (!search && perPage !== 10) {
      // If the search term is cleared and perPage is not 5, reset perPage to 5
      setPerPage(10);
    }
  }, [search, perPage]);

  // ADD DATASET
  const addDataset = useMutation(createDatasets, {
    onSuccess: () => {
      queryClient.invalidateQueries(["datasets"]);
      getDatasets.refetch();
      setMessage({ display: true, theme: "create", name: "Dataset" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
    onSettled: () => {
      setSubmitLoader(false);
    },
  });

  // UPDATE DATASET
  const editDataset = useMutation(updateDatasets, {
    onSuccess: () => {
      queryClient.invalidateQueries(["datasets"]);
      getDatasets.refetch();
      setMessage({ display: true, theme: "update", name: "Dataset" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
    onSettled: () => {
      setSubmitLoader(false);
    },
  });

  // DELETE DATASET
  const removeDataset = useMutation(deleteDatasets, {
    onSuccess: () => {
      queryClient.invalidateQueries(["datasets"]);
      getDatasets.refetch();
      setMessage({ display: true, theme: "delete", name: "Dataset" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
    onSettled: () => {
      setSubmitLoader(false);
    },
  });

  // DUPLICATE DATASET
  const copyDataset = useMutation(duplicateDataset, {
    onSuccess: () => {
      queryClient.invalidateQueries(["datasets"]);
      getDatasets.refetch();
      setMessage({ display: true, theme: "copy", name: "Dataset" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
    onSettled: () => {
      setSubmitLoader(false);
    },
  });

  return (
    <div className="dataset">
      <SuccessMsg display={msg.display} theme={msg.theme} name={msg.name} />
      {/* TOP */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="top">
        <Layout title="Datasets" />
        <button
          onClick={() => {
            setForm(!getForm);
          }}
        >
          <span className="material-symbols-outlined">add</span>
          <p>Add Dataset</p>
        </button>
      </div>
      {/* FILTER */}
      <div className="filter-style">
        <div className="filter">
          <span className="material-symbols-outlined">search</span>
          <input
            type="text"
            placeholder="Search by dataset name..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="style">
          <button
            className={infoStyle == "card" ? "grid" : ""}
            onClick={() => {
              setInfoStyle("card");
            }}
          >
            <span className="material-symbols-outlined">grid_view</span>
          </button>
          <button
            className={infoStyle == "table" ? "grid" : ""}
            onClick={() => {
              setInfoStyle("table");
            }}
          >
            <span className="material-symbols-outlined">view_agenda</span>
          </button>
        </div>
      </div>
      {/* INFORMATION */}
      {getDatasets.isLoading ? (
        <CardLoader />
      ) : getDatasets.data.data && getDatasets.data.data.length > 0 ? (
        infoStyle == "card" ? (
          <DatasetCard data={getDatasets.data} search={search} />
        ) : (
          <DatasetTable
            submitLoader={submitLoader}
            setSubmitLoader={setSubmitLoader}
            data={getDatasets.data.data}
            copyFun={copyDataset}
            removeFun={removeDataset}
            search={search}
            setForm={setForm}
            setReason={setReason}
            elementId={elementId}
            setElementId={setElementId}
          />
        )
      ) : (
        <EmptyData type="dataset" />
      )}
      {/* ADD FORM */}
      <Pagination data={getDatasets.data} setPage={setPage} />
      <DatasetForm
        submitLoader={submitLoader}
        setSubmitLoader={setSubmitLoader}
        reason={reason}
        setReason={setReason}
        getForm={getForm}
        setForm={setForm}
        addFun={addDataset}
        updateFun={editDataset}
        allDatasets={getDatasets.data?.data}
        elementId={elementId}
      />
    </div>
  );
}
