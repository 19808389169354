import React, { useEffect, useState } from "react";
const RoleForm = ({ open, setOpen, addRole, editingRole, editRole = [] }) => {
  useEffect(() => {
    if (editRole.length) {
      setName(editRole[0].name);
    }
  }, [editRole]);

  const [name, setName] = useState("");

  const [errors, setErrors] = useState({
    name: "",
  });
  const validateForm = () => {
    const newErrors = {};

    if (!name.trim()) {
      newErrors.username = "Name is required";
    }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      const data = {
        name,
      };
      if (editRole.length) {
        const editData = {
          id: editRole[0].id,
          name,
        };
        editingRole.mutate(editData);
      } else {
        addRole.mutate(data);
      }

      setOpen(!open);
    }
    setName("");
  };
  return (
    <>
      {/* BG CONTENT */}
      <div
        className="bg-form"
        style={{ display: open ? "block" : "none" }}
      ></div>
      {/* CONTENT */}
      <section
        className="connector-form"
        style={{
          transform: open
            ? "translate(-50%, 0) scale(1)"
            : "translate(-50%, 0) scale(0)",
        }}
      >
        {/* HEAD */}
        <div className="flex justify-between">
          <h3>{editingRole ? "Edit role" : "Add role"}</h3>
          <div className="head">
            <button onClick={() => setOpen(!open)}>
              <span className="material-symbols-outlined">close</span>
            </button>
          </div>
        </div>

        {/* FORM */}
        <form onSubmit={handleSubmit}>
          <div className="form">
            <div className="inps">
              <div className="inp">
                <label>Name *</label>
                <input
                  value={name}
                  name="username"
                  type="text"
                  placeholder="Enter Name"
                  onChange={(e) => setName(e.target.value)}
                />
                <div className="required-error">{errors.username}</div>
              </div>
            </div>

            <div className="submit">
              <button type="button" onClick={() => setOpen(!open)}>
                Cancel
              </button>
              <button>{editingRole ? "Update" : "Create"}</button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default RoleForm;
