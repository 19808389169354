import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useStateContext } from './ContextProvider';

export default function AuthContext() {

  const { token } = useStateContext()
  const navigate = useNavigate();

  // CHECK USER (LOGIN => YES | NO)
  useEffect(() => {
      token && navigate("/user") 
  }, [0])


  return (
    <div className="auth">
        <Outlet />
    </div>
  )
}
