import axios from "axios";
import { API_URL, TOKEN } from "../conf.js";

// FETCH ALL ROLES
export async function getAllRoles(page, per_page) {
  const response = await axios.get(
  `${API_URL}api/roles?page=${page}&per_page=${per_page}`,
    {
      headers: { Authorization: `Bearer ${TOKEN}` },
    }
  );

  return response.data.data;
}

// ADD ROLE
export async function addRole(data) {
  return await axios.post(`${API_URL}api/roles`, data, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}

// EDIT ROLE
export async function editRole(data) {
  return await axios.patch(`${API_URL}api/roles/${data.id}`, data, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}
// DELETE ROLE
export async function deleteRole(id) {
  return await axios.delete(`${API_URL}api/roles/${id}`, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}
