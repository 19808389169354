import React, { useState, useEffect } from "react";
import WorkflowForm from "../../components/dashboard/forms/WorkflowForm";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Backdrop, CircularProgress } from "@mui/material";
import SuccessMsg from "../../components/dashboard/pop/SuccessMsg";
import Layout from "../../components/dashboard/layouts/Layout";
import {
  createWorkflow,
  deleteWorkflow,
  duplicateWorkflow,
  getAllWorkflows,
  updateWorkflow,
} from "../../api/WorkflowApi.js";
import CardLoader from "../../components/dashboard/loaders/CardLoader";
import EmptyData from "../../components/dashboard/empty/EmptyData";
import WorkflowCard from "../../components/dashboard/cards/WorkflowCard";
import WorkflowTable from "../../components/dashboard/tables/WorkflowTable";
import Pagination from "../../components/Pagination.jsx";

export default function Workflow() {
  // INOF STYLE
  const [infoStyle, setInfoStyle] = useState("table");
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(10);

  // POP MESSGAE
  const [msg, setMessage] = useState({ display: false, theme: "", name: "" });

  // GET FORM
  const [getForm, setForm] = useState(false);
  const queryClient = useQueryClient();
  const [reason, setReason] = useState("create");
  const [elementId, setElementId] = useState(null);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [page, setPage] = useState(1);

  // CONNECTOR INFORMATIONS
  const getWorkFlows = useQuery(["getWorkFlows", page, perPage], () =>
    getAllWorkflows(page, perPage)
  );
  useEffect(() => {
    if (search) {
      // If there's a search term, reset page to 1 and set perPage to 9999
      setPage(1);
      setPerPage(9999);
    } else if (!search && perPage !== 10) {
      // If the search term is cleared and perPage is not 5, reset perPage to 5
      setPerPage(10);
    }
  }, [search, perPage]);
  // ADD WORKFLOW
  const addWorkflow = useMutation(createWorkflow, {
    onSuccess: () => {
      queryClient.invalidateQueries(["workflows"]);
      getWorkFlows.refetch();
      setMessage({ display: true, theme: "create", name: "Workflow" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
    onSettled: () => {
      setSubmitLoader(false);
    },
  });

  // UPDATE WORKFLOW
  const editWorkflow = useMutation(updateWorkflow, {
    onSuccess: () => {
      queryClient.invalidateQueries(["workflows"]);
      getWorkFlows.refetch();
      setMessage({ display: true, theme: "update", name: "Workflow" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
    onSettled: () => {
      setSubmitLoader(false);
    },
  });

  // DELETE WORKFLOW
  const removeWorkflow = useMutation(deleteWorkflow, {
    onSuccess: () => {
      queryClient.invalidateQueries(["workflows"]);
      getWorkFlows.refetch();
      setMessage({ display: true, theme: "delete", name: "Workflow" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
    onSettled: () => {
      setSubmitLoader(false);
    },
  });

  // DUPLICATE WORKFLOW
  const copyWorkflow = useMutation(duplicateWorkflow, {
    onSuccess: () => {
      queryClient.invalidateQueries(["workflows"]);
      getWorkFlows.refetch();
      setMessage({ display: true, theme: "copy", name: "Workflow" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
      setSubmitLoader(false);
    },
    onSettled: () => {
      setSubmitLoader(false);
    },
  });

  return (
    <div className="workflow">
      <SuccessMsg display={msg.display} theme={msg.theme} name={msg.name} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* TOP */}
      <div className="top">
        <Layout title="Workflows" />
        <button
          onClick={() => {
            setForm(!getForm);
          }}
        >
          <span className="material-symbols-outlined">add</span>
          <p>Add Workflow</p>
        </button>
      </div>

      {/* FILTER */}
      <div className="filter-style">
        <div className="filter">
          <span className="material-symbols-outlined">search</span>
          <input
            type="text"
            placeholder="Search by workflow name..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="style">
          <button
            className={infoStyle === "card" ? "grid" : ""}
            onClick={() => {
              setInfoStyle("card");
            }}
          >
            <span className="material-symbols-outlined">grid_view</span>
          </button>

          <button
            className={infoStyle === "table" ? "grid" : ""}
            onClick={() => {
              setInfoStyle("table");
            }}
          >
            <span className="material-symbols-outlined">view_agenda</span>
          </button>
        </div>
      </div>
      {/* INFORMATION */}
      {getWorkFlows.isLoading ? (
        <CardLoader />
      ) : getWorkFlows.data && getWorkFlows.data.data.length > 0 ? (
        infoStyle === "card" ? (
          <WorkflowCard data={getWorkFlows.data.data} search={search} />
        ) : (
          <WorkflowTable
            submitLoader={submitLoader}
            setSubmitLoader={setSubmitLoader}
            data={getWorkFlows.data.data}
            copyFun={copyWorkflow}
            removeFun={removeWorkflow}
            search={search}
            setForm={setForm}
            setReason={setReason}
            elementId={elementId}
            setElementId={setElementId}
          />
        )
      ) : (
        <EmptyData type="workflow" />
      )}
      <Pagination data={getWorkFlows.data} setPage={setPage} />
      {/* Workflow From */}
      <WorkflowForm
        submitLoader={submitLoader}
        setSubmitLoader={setSubmitLoader}
        reason={reason}
        getForm={getForm}
        setReason={setReason}
        setForm={setForm}
        addFun={addWorkflow}
        updateFun={editWorkflow}
        allWorkflows={getWorkFlows.data?.data}
        elementId={elementId}
      />
    </div>
  );
}
