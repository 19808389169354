import React, { useEffect, useState } from "react";
import UsersTable from "./users/UsersTable";
import Layout from "../../components/dashboard/layouts/Layout";
import UserForm from "../../components/dashboard/forms/UserForm";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";

import SuccessMsg from "../../components/dashboard/pop/SuccessMsg";
import { useStateContext } from "../../context/ContextProvider";
import RolesTable from "./roles/RolesTable";
import { addRole, editRole, getAllRoles } from "../../api/RolesApi";
import RoleForm from "../../components/dashboard/forms/RoleForm";

export default function Roles() {
  const navigate = useNavigate();
  const { user, setToken, setUser } = useStateContext();
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (user && user.role.name.toLowerCase() != "admin") {
      navigate("/user/settings");
    }
  }, []);
  const [msg, setMessage] = useState({ display: false, theme: "", name: "" });

  const { data, isLoading, refetch } = useQuery(["getRoles", page], () =>
    getAllRoles(page, 5)
  );
  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();
  // Create User
  const createRole = useMutation(addRole, {
    onSuccess: () => {
      queryClient.invalidateQueries(["roles"]);
      refetch();
      setMessage({ display: true, theme: "create", name: "User" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
  });

  // Edit User
  const updateRole = useMutation(editRole, {
    onSuccess: () => {
      queryClient.invalidateQueries(["roles"]);
      refetch();
      setMessage({ display: true, theme: "update", name: "Role" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
  });
  return (
    <div className="p-[2rem]">
      <SuccessMsg display={msg.display} theme={msg.theme} name={msg.name} />
      <div className="flex justify-between items-center ">
        <h3 className="flex font-normal items-center text-lg pb-5">
          <Link to={`/user/settings`} className="text-black underline">
            Settings
          </Link>
          &#62;
          <Layout title="Roles" />
        </h3>
        <button
          onClick={() => setOpen(!open)}
          className="items-center px-4 text-sm py-2 rounded-2xl flex"
        >
          <span class="material-symbols-outlined">add</span>
          <p>Add role</p>
        </button>
      </div>
      <RolesTable editingRole={updateRole} />
      <RoleForm addRole={createRole} setOpen={setOpen} open={open} />
    </div>
  );
}
