import React, { useEffect, useState } from "react";
import { getAllConnectors } from "../../../api/ConnectorsApi";
import { useQuery } from "@tanstack/react-query";
import { API_URL, TOKEN } from "../../../conf";
import axios from "axios";
export default function DatasetForm({
  reason,
  setReason,
  getForm,
  setForm,
  addFun,
  updateFun,
  elementId,
  allDatasets,
  submitLoader,
  setSubmitLoader,
}) {
  // USER INFO

  // CONNECTORS INFORMATION
  const getConnectors = useQuery(["getConnectors"], () =>
    getAllConnectors(1, 9999, "")
  );

  // FORM VALUES
  const [id_dataset, set_id_dataset] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [connector, setConnector] = useState("");
  const [connectorType, setConnectorType] = useState("");
  const [bucket, setBucket] = useState("");
  const [path, setPath] = useState("");
  const [collection, setCollection] = useState("");
  const [type, setType] = useState("");
  const [loadingTables, setLoadingTables] = useState(false);
  const [tables, setTables] = useState([]);

  // Additional state to control the rendering of schema and table fields

  useEffect(() => {
    // SET FORM
    if (reason === "update") {
      setTables([]);
      let dataset = allDatasets.filter((item) => item.id_dataset === elementId);

      const dataset_options = dataset[0].dataset_options;

      setType(
        dataset_options.path && dataset_options.path.includes(".")
          ? dataset_options.path.split(".")[1]
          : ""
      );

      set_id_dataset(dataset[0].id_dataset);
      setConnectorType(dataset_options.schema ? "Connector" : "S3");
      setName(dataset[0].name);
      setDescription(dataset[0].description ? dataset[0].description : "");

      setBucket(
        dataset_options.schema ? dataset_options.schema : dataset_options.bucket
      );
      setPath(
        dataset_options.table
          ? dataset_options.table
          : dataset_options.path && dataset_options.path
          ? dataset_options.path
          : ""
      );
      setCollection(
        dataset_options.collection ? dataset_options.collection : ""
      );
      setConnector(dataset[0].id_connector);
    }
  }, [elementId, getForm, setReason, reason, allDatasets]);

  const handleControllForm = () => {
    setForm(false);
    setReason("create");
    set_id_dataset(null);
    setName("");
    setDescription("");
    setBucket("");
    setPath("");
    setCollection("");
    setConnector("");
    setType("");
  };

  function handleForm() {
    if (
      name.length > 0 &&
      (path.length > 0 || collection.length > 0) &&
      connector !== ""
    ) {
      const dataset_options =
        connectorType == "S3"
          ? { bucket: bucket, path: path }
          : connectorType == "API"
          ? { path: path + "." + type }
          : connectorType == "MongoDB"
          ? { collection: collection }
          : { schema: bucket, table: path };

      const data = {
        name: name,
        description: description,
        id_connector: connector,
        dataset_options: dataset_options,
        id_dataset: id_dataset,
      };
      reason === "create" ? addFun.mutate(data) : updateFun.mutate(data);
      handleControllForm();
      setSubmitLoader(true);
    } else {
      setRequiredField(true);
    }
  }
  const fetchTables = async (dbType, url, user, password) => {
    const response = await axios.post(
      `${API_URL}api/tablesCheck`,
      {
        db_type: dbType,
        url: url,
        user: user,
        password: password,
      },
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    );
    if (response) {
      if (dbType.toLowerCase() == "mongodb") {
        setTables(response.data.collections);
      } else if (dbType.toLowerCase() == "mysql") {
        setTables(response.data.tables);
      } else if (
        dbType.toLowerCase() == "sql server" ||
        dbType.toLowerCase() == "postgresql" ||
        dbType.toLowerCase() == "oracle"
      ) {
        setTables(response.data.schemas);
      }
    }
  };

  const [requiredField, setRequiredField] = useState(false);

  useEffect(() => {
    if (connector) {
      const connectorData = getConnectors.data.data.find(
        (item) => item.id_connector == connector
      );
      setConnectorType(connectorData.type);

      fetchTables(
        connectorData.type,
        connectorData.connection_options.url,
        connectorData.connection_options.user,
        connectorData.connection_options.password
      );
    }
  }, [connector]);

  if (getConnectors.isLoading) {
    return <p>Loading...</p>;
  }
  

  const renderConnectorSpecificFields = () => {
    if (connectorType == "S3") {
      return (
        <>
          <div className="inp">
            <label>Bucket *</label>
            <input
              type="text"
              placeholder={`Enter bucket's name`}
              value={bucket}
              onChange={(e) => setBucket(e.target.value)}
            />
            <div className="required-error">
              {requiredField && bucket === ""
                ? `Oops! Bucket field is required.`
                : ""}
            </div>
          </div>
          <div className="inp">
            <label>Path *</label>
            <input
              type="text"
              placeholder={`Enter path's name`}
              value={path}
              onChange={(e) => setPath(e.target.value)}
            />
            <div className="required-error">
              {requiredField && path === ""
                ? `Oops! Path field is required.`
                : ""}
            </div>
          </div>
        </>
      );
    } else if (connectorType == "API") {
      return (
        <>
          <div className="inp">
            <label>Type *</label>
            <select
              value={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              <option value="">-- Select type --</option>
              <option value="csv">CSV</option>
              <option value="xml">XML</option>
              <option value="json">JSON</option>
            </select>
            <div className="required-error">
              {requiredField && type === ""
                ? "Oops! type field is required."
                : ""}
            </div>
          </div>
          <div className="inp">
            <label>Path *</label>
            <input
              type="text"
              placeholder={`Enter bucket's name`}
              value={path}
              onChange={(e) => setPath(e.target.value)}
            />
            <div className="required-error">
              {requiredField && bucket === ""
                ? `Oops! Path field is required.`
                : ""}
            </div>
          </div>
        </>
      );
    } else if (connectorType == "MongoDB") {
      return (
        <>
          <div className="inp">
            <label>Collection *</label>
            <select
              value={collection}
              onChange={(e) => {
                setCollection(e.target.value);
              }}
            >
              <option value="">-- Select Collection --</option>
              {tables
                ? tables.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))
                : "Loading"}
            </select>
            <div className="required-error">
              {requiredField && collection === ""
                ? "Oops! Collection field is required."
                : ""}
            </div>
          </div>
        </>
      );
    } else if (connectorType.toLocaleLowerCase() == "mysql") {
      return (
        <>
          <div className="inp">
            <label>Tables *</label>
            <select
              value={path}
              onChange={(e) => {
                setPath(e.target.value);
              }}
            >
              <option value="">-- Select Tables --</option>
              {tables?.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
            <div className="required-error">
              {requiredField && path === ""
                ? "Oops! Collection field is required."
                : ""}
            </div>
          </div>
        </>
      );
    } else if (
      connectorType.toLocaleLowerCase() == "sql server" ||
      connectorType.toLocaleLowerCase() == "postgresql" ||
      connectorType.toLocaleLowerCase() == "oracle"
    ) {
      return (
        <>
          <div className="inp">
            <label>Schema *</label>
            <select
              value={bucket}
              onChange={(e) => {
                setBucket(e.target.value);
              }}
            >
              <option value="">-- Select Schema --</option>
              {tables?.map((item, index) => (
                <option key={index} value={item?.schema}>
                  {item?.schema}
                </option>
              ))}
            </select>
            <div className="required-error">
              {requiredField && connector === ""
                ? "Oops! Connector field is required."
                : ""}
            </div>
          </div>
          <div className="inp">
            <label>Table *</label>
            <select
              value={path}
              onChange={(e) => {
                setPath(e.target.value);
              }}
            >
              <option value="">
                {bucket ? "-- Select table --" : "Select Schema first"}
              </option>
              {bucket && tables.length > 0
                ? tables
                    ?.find((item) => item.schema == bucket)
                    .tables?.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })
                : ""}
            </select>
            <div className="required-error">
              {requiredField && connector === ""
                ? "Oops! Connector field is required."
                : ""}
            </div>
          </div>
        </>
      );
    } else {
      return;
    }
  };

  return (
    <>
      <div
        className="bg-form"
        style={{ display: getForm ? "block" : "none" }}
      ></div>
      <section
        className="connector-form"
        style={{
          transform: getForm
            ? "translate(-50%, 0) scale(1)"
            : "translate(-50%, 0) scale(0)",
        }}
      >
        <div className="head">
          <h3>{reason === "create" ? "New" : "Update"} Dataset</h3>
          <button
            onClick={() => {
              handleControllForm();
            }}
          >
            <span className="material-symbols-outlined">close</span>
          </button>
        </div>
        <div className="form">
          <div className="inps">
            <div className="inp">
              <label>Connector *</label>
              <select
                value={connector}
                onChange={(e) => {
                  setTables([]);
                  setBucket("");
                  setConnector(e.target.value);
                }}
              >
                <option value="">-- Select connector --</option>
                {getConnectors.data.data.map((item) => (
                  <option key={item.id_connector} value={item.id_connector}>
                    {item.name}
                  </option>
                ))}
              </select>
              <div className="required-error">
                {requiredField && connector === ""
                  ? "Oops! Connector field is required."
                  : ""}
              </div>
            </div>
            <div className="inp">
              <label>Name *</label>
              <input
                type="text"
                placeholder="Enter dataset's name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <div className="required-error">
                {requiredField && name === ""
                  ? "Oops! Name field is required."
                  : ""}
              </div>
            </div>
          </div>
          <div className="inps">{renderConnectorSpecificFields()}</div>
          <div className="inp">
            <label>Description</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              placeholder="Add description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
            <div className="required-error"></div>
          </div>
          <div className="submit">
            <button
              onClick={() => {
                handleControllForm();
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleForm();
              }}
            >
              {reason === "create" ? "Create" : "Update"}
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
