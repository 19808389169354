import axios from "axios";
import { API_URL, TOKEN } from "../conf.js";

function fixJsonString(jsonString) {
  const fixedJsonString = jsonString
    .replace(/'/g, '"')
    .replace(/"({[^{}]*})"/g, "$1");
  return fixedJsonString;
}

export async function getEtlStat(id) {
  const response = await axios.get(`${API_URL}api/etls/${id}`, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
  return JSON.parse(fixJsonString(response.data.data.stat));
}

export async function getEtlJsonPlan(id) {
  const response = await axios.get(`${API_URL}api/etls/${id}`, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
  return JSON.parse(fixJsonString(response.data.data.json_plan));
}

export async function updateEtlJson(data) {
  const { etlJSON, etlId, state } = data;
  const etl = await axios.get(`${API_URL}api/etls/${etlId}`, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
  const response = await axios.put(
    `${API_URL}api/etls/${etlId}`,
    {
      name: etl.data.name,
      user_id: etl.data.user_id,
      description: etl.data.description,
      json_plan: JSON.stringify(etlJSON).replace(/"/g, "'"),
      stat: JSON.stringify(state).replace(/"/g, "'"),
    },
    { headers: { Authorization: `Bearer ${TOKEN}` } }
  );
  return response.data.body;
}

export async function getOneEtl(id) {
  const response = await axios.get(`${API_URL}api/etls/${id}`, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
  return response.data.data;
}

export async function getAllEtls(page, per_page, search) {
  const response = await axios.get(
    `${API_URL}api/etls?page=${page}&per_page=${per_page}&search=${search}`,
    {
      headers: { Authorization: `Bearer ${TOKEN}` },
    }
  );
  return response.data.data;
}

export async function postEtl(data) {
  const { name, description, user_id } = data;
  return await axios.post(
    `${API_URL}api/etls`,
    {
      name: name,
      description: description,
      user_id: user_id,
      json_plan: JSON.stringify({
        datasets: [],
        dag: { input_dataset_ids: [], transformers: [], outputs: [] },
      }).replace(/"/g, "'"),
      stat: JSON.stringify({ nodes: [], edges: [] }).replace(/"/g, "'"),
    },
    { headers: { Authorization: `Bearer ${TOKEN}` } }
  );
}

export async function duplicateEtl(id) {
  return await axios.post(`${API_URL}api/etls/${id}/duplicate`, null, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}

export async function putEtl(data) {
  const { id_etl, name, description, json_plan, stat } = data;
  const etl = await axios.get(`${API_URL}api/etls/${id_etl}`, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
  // JSON PLAN FORMAT => obj -> json
  const jsonString = json_plan
    ? JSON.stringify(json_plan).replace(
        /(['"])?([a-zA-Z0-9_]+)(['"])?:\s*"undefined"/g,
        function (match, p1, p2, p3) {
          if (p1 && p3 && !isNaN(p2)) {
            // If there are quotes around the key and value and the key is a number, don't add a space
            return `${p1}${p2}${p3}:`;
          } else {
            // Otherwise, add a space before the colon
            return p1 ? `${p1}${p2}${p3}:` : `${p2}:`;
          }
        }
      )
    : etl.data.data.json_plan;
  return await axios.put(
    `${API_URL}api/etls/${id_etl}`,
    {
      name: name ? name : etl.data.data.name,
      user_id: etl.data.data.user_id,
      description: description ? description : etl.data.data.description,
      json_plan: jsonString,
      stat: stat ? JSON.stringify(stat).replace(/"/g, "'") : etl.data.stat,
    },
    { headers: { Authorization: `Bearer ${TOKEN}` } }
  );
}

export async function RemoveEtl(etlId) {
  return await axios.delete(`${API_URL}api/etls/${etlId}`, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}
