import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CheckDelete from "../pop/CheckDelete";
import SuccessMsg from "../pop/SuccessMsg";

export default function EtlTable({
  data,
  copyFun,
  removeFun,
  setReason,
  setForm,
  elementId,
  setElementId,
  search,
  handleRun,
  setSubmitLoader,
  submitLoder,
}) {
  const [remove, setRemove] = useState(false);
  const [elementName, setName] = useState("");
  const [popDisplay, setPop] = useState(false);

  const handleDelete = (id, name) => {
    setElementId(id);
    setRemove(true);
    setName(name);
  };

  const handleUpdate = (id) => {
    setElementId(id);
    setReason("update");
    setForm(true);
  };

  const handleRunLog = (id) => {
    setElementId(id);
    handleRun(id);
  };

  const getOutputData = (jsonPlan) => {
    try {
      const json = JSON.parse(jsonPlan);
      return json.datasets.find((item) => item.id === "output1");
    } catch (error) {
      console.error("Invalid JSON:", error.message, "JSON:", jsonPlan);
      return null;
    }
  };

  useEffect(() => {
    if (data && data.length > 0 && data[0].json_plan) {
      const outputData = getOutputData(data[0].json_plan);
      if (outputData) {
        const connectorId = outputData.connector_id;
        const table = outputData.table;
      }
    }
  }, [data]);

  return (
    <table>
      <SuccessMsg display={popDisplay} theme="done" name="run log" />
      <thead>
        <tr>
          <th>ETL</th>
          <th>Description</th>
          <th>Result</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, i) => {
          const outputData = item.json_plan
            ? getOutputData(item.json_plan)
            : null;
          const connectorId = outputData?.connector_id;
          const table = outputData?.table;
          return (
            item.name.toLowerCase().includes(search.toLowerCase()) && (
              <tr key={i}>
                <td data-label="ETL">
                  <Link to={`/user/etl_run/${item.id_etl}`} className="main">
                    <span className="material-symbols-outlined">tenancy</span>
                    <p>{item.name}</p>
                  </Link>
                </td>
                <td data-label="Description">
                  {item.description ? item.description : "---"}
                </td>
                <td>
                  {table ? (
                    <Link
                      to={`/user/sampleDb/${connectorId}/${table}`}
                      className="main underline"
                    >
                      <p>{table}</p>
                    </Link>
                  ) : (
                    <p>run the etl first</p>
                  )}
                </td>
                <td>
                  <Link to={`/user/flow/${item.id_etl}`}>
                    <span className="material-symbols-outlined">
                      conversion_path
                    </span>
                  </Link>
                  <button onClick={() => handleRunLog(item.id_etl)}>
                    <span className="material-symbols-outlined">
                      play_arrow
                    </span>
                  </button>
                  <button onClick={() => copyFun.mutate(item.id_etl)}>
                    <span className="material-symbols-outlined">file_copy</span>
                  </button>
                  <button onClick={() => handleUpdate(item.id_etl)}>
                    <span className="material-symbols-outlined">edit</span>
                  </button>
                  <button onClick={() => handleDelete(item.id_etl, item.name)}>
                    <span className="material-symbols-outlined">delete</span>
                  </button>
                </td>
              </tr>
            )
          );
        })}
      </tbody>
      <CheckDelete
        setSubmitLoader={setSubmitLoader}
        submitLoder={submitLoder}
        remove={remove}
        setRemove={setRemove}
        object="Etl"
        elementId={elementId}
        elementName={elementName}
        removeFun={removeFun}
        setElementId={setElementId}
      />
    </table>
  );
}
