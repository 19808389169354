import React from 'react'

export default function SuccessMsg({display, theme, name}) {

    const themes = [
        {name: 'create', icon: 'done', named: 'created'},
        {name: 'delete', icon: 'delete', named: 'deleted'},
        {name: 'update', icon: 'sync_alt', named: 'updated'},
        {name: 'run log', icon: 'play_arrow', named: 'run'},
        {name: 'save', icon: 'save', named: 'saved'},
        {name: 'copy', icon: 'file_copy', named: 'copied'},
    ]
  return (
    <div className={display ? 'msg success-msg' : 'msg'}>
        <div className="text">
            <span className="material-symbols-outlined">
                {
                    themes.map(item=>{
                        return item.name == theme && item.icon
                    })
                }
            </span>
            <div>
                <p>Yay! Everything worked!</p>
                <h5>  
                    {
                        themes.map(item=>{
                            return item.name == theme && `${name} has been ${item.named} successfully.`
                        })
                    }
                </h5>
            </div>
        </div>
        <button>
            <span className="material-symbols-outlined">close</span>
        </button>
    </div>
  )
}
