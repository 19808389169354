import { Button, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { deleteUser, getAllUsers } from '../../../api/AdminApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import SuccessMsg from '../../dashboard/pop/SuccessMsg';
import CheckDelete from '../../dashboard/pop/CheckDelete';

export default function UsersTable({limit}) {

    const {pathname} = useLocation()

    // FORMAT DATE ( 2023-06-15T12:33:03.000000Z => Jun 15, 2023 )
    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    };

    // POP MESSGAE
    const [msg, setMessage] = useState({ display: false, theme: '', name: '' })
    const [elementId, setElementId] = useState(null)
    const [elementName, setName] = useState('')
    const [remove, setRemove] = useState(false)

    // DELETE CONNECTOR
    const queryClient = useQueryClient()
    const removeUser = useMutation(deleteUser, {
        onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
        refetch()
        setMessage({ display: true, theme: 'delete', name: 'User' })
            setTimeout(() => {
                setMessage({ display: false, theme: '', name: '' })
            }, 3000);
        },
    });
    
    // HANDLE DELETE USER
    const handleDelete = (id, name) => {
        setElementId(id)
        setRemove(true)
        setName(name)
    }

    // USERS INFO
    const { data, isLoading, refetch } = useQuery(['getUsers'], () => getAllUsers() )

    if(isLoading){
        return <p>Loading...</p>
    }

  return (
    <table className='admin-tables'>
        <SuccessMsg display={msg.display} theme={msg.theme} name={msg.name} />
        <thead>
            <tr>
                <th>User</th>
                <th>Joined On</th>
                <th>Connectors</th>
                <th>Datasets</th>
                <th>ETL</th>
            </tr>
        </thead>
        <tbody>
            {
                data.map((item,i)=>{ 
                    const stop = limit ? limit : data.length
                    return  i < stop && <tr key={item.id}>
                                <td>
                                    <div className="user-info">
                                        {
                                            item.avatar 
                                                ? <img src={item.avatar} alt="" />
                                                : <span className="material-symbols-outlined">person </span>
                                        }
                                        <div>
                                            <p>{item.username}</p>
                                            <span>{item.email}</span>
                                        </div>
                                    </div>
                                </td>
                                <td>{formatDate(item.created_at)}</td>
                                <td>
                                    <div className={item.connector_count > 0 ? "status" : "status status-down"}>
                                        <span className='material-symbols-outlined'>input_circle</span>
                                        <p>{item.connector_count}</p>
                                    </div>
                                </td>
                                <td>
                                    <div className={item.dataset_count > 0 ? "status" : "status status-down"}>
                                        <span className='material-symbols-outlined'>input_circle</span>
                                        <p>{item.dataset_count}</p>
                                    </div>
                                </td>
                                <td>
                                    <div className={item.etl_count > 0 ? "status" : "status status-down"}>
                                        <span className="material-symbols-outlined">input_circle</span>
                                        <p>{item.etl_count}</p>
                                    </div>
                                </td>
                                <td>
                                    <button className='delete' onClick={()=> {handleDelete(item.id, item.username)}}>
                                        <span className="material-symbols-outlined">delete</span>
                                    </button>
                                </td>
                            </tr>
                })
            }
        </tbody>

        {/* DELETE */}
        <CheckDelete 
            remove={remove} setRemove={setRemove}
            object='User' elementId={elementId} elementName={elementName}
            removeFun={removeUser} setElementId={setElementId}
            theme='black'
        />
    </table>
  )
}
