import React, { useState } from 'react'

export default function EmptyData({type}) {

  // TYPES
  const [types, setTypes] = useState([
      { name : "connector", title : "Connect Your Data Sources", subtitle : "Start Adding Connectors to Power Your Data Pipeline"},
      { name : "dataset", title : "Organize and Transform Your Data", subtitle : "Begin Creating Datasets to Store and Process Your Data"},
      { name : "etl", title : "Build Your Data Pipeline", subtitle : "Start Creating Your ETL Workflow and Transform Your Data"},
  ])

  return (
      types.map(item=>{
          return item.name == type && <div className='empty'>
                    <img src={require('../../../resources/assets/images/empty.gif')} alt="" />
                    <h3>{item.title}</h3>
                    <p>{item.subtitle}</p>
                </div>
      })
  )
}
