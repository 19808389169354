import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
const UserForm = ({
  open,
  setOpen,
  editingUser,
  addUser,
  roles = [],
  editUser = [],
  submitLoader = false,
  setSubmitLoader,
}) => {
  useEffect(() => {
    if (editUser.length) {
      setUsername(editUser[0].username);
      setEmail(editUser[0].email);
      const roleFind = roles.find((role) => editUser[0].role_id == role.id);

      setRole(roleFind?.id);
    } else {
      if (!role && roles.length > 1) {
        setRole(roles[1].id);
      }
    }
  }, [editUser]);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");

  const [errors, setErrors] = useState({
    username: "",
    email: "",
    password: "",
    role: "",
  });

  const validateForm = () => {
    const newErrors = {};

    if (!username.trim()) {
      newErrors.username = "Name is required";
    }

    if (!email.trim()) {
      newErrors.email = "Email is required";
    }

    if (!password.trim() && !editUser.length) {
      newErrors.password = "Password is required";
    }
    if (!role) {
      newErrors.role = "Role is required";
    }
    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      const data = {
        username,
        email,
        password,
        role_id: role,
      };
      if (editUser.length) {
        const editData = {
          username,
          email,
          password,
          role_id: role,
          id: editUser[0].id,
        };

        editingUser.mutate(editData);
      } else {
        addUser.mutate(data);
      }
      setSubmitLoader(true);
      setUsername("");
      setEmail("");
      setPassword("");
      setRole("");
      setOpen(!open);
    }
  };
  return (
    <>
      {submitLoader && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={submitLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {/* BG CONTENT */}
      <div
        className="bg-form"
        style={{ display: open ? "block" : "none" }}
      ></div>
      {/* CONTENT */}
      <section
        className="connector-form"
        style={{
          transform: open
            ? "translate(-50%, 0) scale(1)"
            : "translate(-50%, 0) scale(0)",
        }}
      >
        {/* HEAD */}
        <div className="flex justify-between">
          <h3>{editUser.length ? "Edit user" : "Add user"}</h3>
          <div className="head">
            <button onClick={() => setOpen(!open)}>
              <span className="material-symbols-outlined">close</span>
            </button>
          </div>
        </div>

        {/* FORM */}
        <form onSubmit={handleSubmit}>
          <div className="form">
            <div className="inps">
              <div className="inp">
                <label>Name *</label>
                <input
                  value={username}
                  name="username"
                  type="text"
                  placeholder="Enter Name"
                  onChange={(e) => setUsername(e.target.value)}
                />
                <div className="required-error">{errors.username}</div>
              </div>
              <div className="inp">
                <label>Email *</label>
                <input
                  value={email}
                  name="email"
                  type="email"
                  placeholder="Enter Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="required-error">{errors.email}</div>
              </div>
            </div>
            <div className="inps">
              <div className="inp">
                <label>Password *</label>
                <input
                  value={password}
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="required-error">{errors.password}</div>
              </div>
              <div className="inp">
                <label>Role *</label>
                <select
                  value={role}
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                >
                  <option value="">-- Select Role --</option>
                  {roles
                    ? roles.map((item, index) => {
                        return (
                          <option
                            key={item.id}
                            selected={index === 1}
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })
                    : "loading"}
                </select>
                <div className="required-error">{errors.role}</div>
              </div>
            </div>

            <div className="submit">
              <button type="button" onClick={() => setOpen(!open)}>
                Cancel
              </button>
              <button>{editUser.length ? "Update" : "Create"}</button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default UserForm;
