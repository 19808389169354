import React from "react";
import Header from "./Header";
import { Helmet } from "react-helmet";

const Layout = ({ children, title }) => {
  return (
    <>
      <Helmet>
        <title>{title} | Hi Group</title>
      </Helmet>
      <div className="">
        <h3 className="font-normal text-lg">{title}</h3>

        <main>{children}</main>
      </div>
    </>
  );
};

export default Layout;
