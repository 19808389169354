import React, { useState } from 'react'
import ReactDOM from 'react-dom/client';
import ReactApexChart from 'react-apexcharts' 

export default function ApexChart() {

  const [info, setInfo] = useState({
          
    series: [{
      name: 'ETL',
      data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
    }],
    options: {
      colors : ['#1a1a1a', '#1a66ff'],
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"],
          fontFamily: "Poppins",
          fontWeight: "normal" 
        }
      },
      
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },

          }
        },
        tooltip: {
          enabled: true,
        },
        labels: {
          style: {
            colors: '#adadad',
            fontFamily: "Poppins",
            fontWeight: "normal" 
          }
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + "%";
          }
        }
      
      },
      title: {
        // text: 'ETL Statistics',
        text: '',
        // floating: true,
        offsetY: 330,
        align: 'center',
        style: {
          color: '#adadad',
          fontSize: '18px',
          fontFamily: "Poppins",
          fontWeight: "normal"
          
        }
      }
    },
  
  }
)

  return (
      <div className="chart">
          <ReactApexChart options={info.options} series={info.series} type="bar" height={250} />
      </div>  )
}
