import React, { useEffect, useState } from "react";
import UsersTable from "./users/UsersTable";
import Layout from "../../components/dashboard/layouts/Layout";
import UserForm from "../../components/dashboard/forms/UserForm";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { addUser, deleteUser, editUser, getAllUsers } from "../../api/UsersApi";
import SuccessMsg from "../../components/dashboard/pop/SuccessMsg";
import { useStateContext } from "../../context/ContextProvider";
import { getAllRoles } from "../../api/RolesApi";
import Pagination from "../../components/Pagination";

export default function Users() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const { data: roles, isLoading: rolesLoading } = useQuery(["getRoles"], () =>
    getAllRoles(1, 99999)
  );
  const navigate = useNavigate();

  const { user, setToken, setUser } = useStateContext();
  useEffect(() => {
    if (user && user.role.name.toLowerCase() != "admin") {
      navigate("/user/settings");
    }
  }, [user, navigate]);
  const [msg, setMessage] = useState({ display: false, theme: "", name: "" });
  const removeUser = useMutation(deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["getUsers"]);
      refetch();
      setMessage({ display: true, theme: "delete", name: "User" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
  });
  const { data, isLoading, refetch } = useQuery(
    ["getUsers", page, perPage],
    () => getAllUsers(page, perPage)
  );
  useEffect(() => {
    if (search) {
      // If there's a search term, reset page to 1 and set perPage to 9999
      setPage(1);
      setPerPage(9999);
    } else if (!search && perPage !== 5) {
      // If the search term is cleared and perPage is not 5, reset perPage to 5
      setPerPage(5);
    }
  }, [search, perPage]);

  const [open, setOpen] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const queryClient = useQueryClient();

  // Create User
  const createUser = useMutation(addUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["getUsers"]);
      refetch();
      setMessage({ display: true, theme: "create", name: "User" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
    },
    onSettled: () => {
      setSubmitLoader(false);
    },
  });

  // Edit User
  const updateUser = useMutation(editUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["getUsers"]);
      refetch();
      setMessage({ display: true, theme: "update", name: "User" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
      setSubmitLoader(false);
    },
  });
  return (
    <div className="p-[2rem]">
      <SuccessMsg display={msg.display} theme={msg.theme} name={msg.name} />
      <div className="flex justify-between items-center ">
        <h3 className="flex font-normal items-center text-lg pb-5">
          <Link to={`/user/settings`} className="text-black underline">
            Settings
          </Link>
          &#62;
          <Layout title="Users" />
        </h3>

        <button
          onClick={() => setOpen(!open)}
          className="items-center px-4 text-sm py-2 rounded-2xl flex"
        >
          <span class="material-symbols-outlined">add</span>
          <p>Add User</p>
        </button>
      </div>
      <div className="flex bg-black bg-opacity-5 rounded-2xl items-center px-2 py-1  mb-10 w-1/2">
        <span class="material-symbols-outlined text-secondarycolor mr-2">
          search
        </span>
        <input
          className="p-3 w-full bg-transparent"
          type="text"
          placeholder="Search"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <UsersTable
        roles={roles}
        submitLoader={submitLoader}
        setSubmitLoader={setSubmitLoader}
        search={search}
        editingUser={updateUser}
        data={data}
        rolesLoading={rolesLoading}
        removeUser={removeUser}
      />
      <UserForm
        submitLoader={submitLoader}
        setSubmitLoader={setSubmitLoader}
        roles={roles?.data}
        addUser={createUser}
        setOpen={setOpen}
        open={open}
      />
      <Pagination data={data} page={page} setPage={setPage} search={search} />
    </div>
  );
}
