import { Button, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckDelete from "../../dashboard/pop/CheckDelete";
import { Link } from "react-router-dom";

export default function ConnectorTable({
  data,
  copyFun,
  removeFun,
  setReason,
  setForm,
  elementId,
  setElementId,
  search,
  submitLoader,
  setSubmitLoader,
}) {
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const [remove, setRemove] = useState(false);

  // REMOVE ELEMENT NAME
  const [elementName, setName] = useState("");

  const handleDelete = (id, name) => {
    setElementId(id);
    setRemove(true);
    setName(name);
  };

  const handleUpdate = (id) => {
    setElementId(id);
    setReason("update");
    setForm(true);
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Connector</th>
          <th>Description</th>
          <th>Type</th>
          <th>Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, i) => {
          return (
            item.name.toLowerCase().indexOf(search.toLowerCase()) > -1 && (
              <tr key={i}>
                <Link
                  to={
                    item.type.toLowerCase() === "s3" ||
                    item.type.toLowerCase() === "api"
                      ? "#"
                      : `/user/catalog/${item.id_connector}`
                  }
                  className={
                    item.type.toLowerCase() === "s3" ||
                    item.type.toLowerCase() === "api"
                      ? "cursor-default"
                      : "cursor-pointer"
                  }
                >
                  <td data-label="Connector">
                    <div className="main">
                      <span className="material-symbols-outlined">sensors</span>
                      <p
                        className={`${
                          item.type.toLowerCase() == "s3" ||
                          item.type.toLowerCase() == "api"
                            ? "no-underline"
                            : "underline"
                        }`}
                      >
                        {item.name}
                      </p>
                    </div>
                  </td>
                </Link>
                <td data-label="Description">
                  {item.description ? item.description : "---"}
                </td>
                <td data-label="Type">
                  <span className="type">{item.type}</span>
                </td>
                <td data-label="Date">{formatDate(item.created_at)}</td>
                <td>
                  <button
                    onClick={() => {
                      copyFun.mutate(item.id_connector);
                    }}
                  >
                    <span className="material-symbols-outlined">file_copy</span>
                  </button>
                  <button
                    onClick={() => {
                      handleUpdate(item.id_connector);
                    }}
                  >
                    <span className="material-symbols-outlined">edit</span>
                  </button>
                  <button
                    onClick={() => {
                      handleDelete(item.id_connector, item.name);
                    }}
                  >
                    <span className="material-symbols-outlined">Delete</span>
                  </button>
                </td>
              </tr>
            )
          );
        })}
      </tbody>

      {/* DELETE */}
      <CheckDelete
        submitLoader={submitLoader}
        setSubmitLoader={setSubmitLoader}
        remove={remove}
        setRemove={setRemove}
        object="Connector"
        elementId={elementId}
        elementName={elementName}
        removeFun={removeFun}
        setElementId={setElementId}
      />
    </table>
  );
}
