import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useRef, useState } from 'react'
import { getAllUsers } from '../../../api/AdminApi'

export default function PlaygroundForm({getForm, setForm, addFun, updateFun, reason, setReason, playgounds, elementId}) {

    // FORM INFO
    const [id_playground, set_id_playground] = useState(null)
    const [name, setName] = useState("")
    const [userId, setUserId] = useState("")
    const [creative, setCreative] = useState(true)
    const [types, setTypes] = useState([
        {name : "MySql", selected: true}, {name : "PostgreSQL", selected: false}, {name : "MS SQL Server", selected: false},
        {name : "MariaDB", selected: false}, {name : "Snowflake", selected: false},
        {name : "Google BigQuery", selected: false}, {name : "SQLite", selected: false}
    ])

    const handleTypes = (name) => {
        const newType = types.map(item=>{
            return item.name == name ? {name : item.name, selected: true} : {name : item.name, selected: false}
        })
        setTypes(newType)
    }

    useEffect(() => {
        // SET FORM
          if(reason == 'update'){
              let playground = playgounds.data.filter((item)=> item.id_playground == elementId )
              set_id_playground(playground[0].id_playground)
              setName(playground[0].name)
              setCreative(playground[0].creativity)
              setUserId(playground[0].user_id)
              handleTypes(playground[0].type)
          }  
      }, [elementId, getForm, setReason])

    // HANDLE FORM
    function handleForm() {
        // CHECK VALUES
        if(name.length > 0 && userId ){
            const typeSelected = types.find(item=>{ return item.selected })
            const payload = {
                id_playground : id_playground,
                name : name,
                type : typeSelected.name,
                creativity : creative,
                user_id : userId
            }
            // CREATE DATASET || UPDATE DATASET
            reason == 'create' ? addFun.mutate(payload) : updateFun.mutate(payload);
            handleControllForm()
        }else{
            // setRequiredField(true)
        }
    }

    // HANDLE OPEN & CLOSE FORM
    const handleControllForm = () => {
        setForm(false);
        setReason('create')
        setName('')
        setUserId("")
        set_id_playground(null)
    }

    // GET ALL USERS 
    const getUsers = useQuery(['getUsers'], () => getAllUsers() )


  return (
    <>
        {/* BG CONTENT */}
        <div className="bg-form" style={{display: getForm ? 'block' : 'none'}}></div>
        {/* CONTENT */}
        <section className='connector-form playground-form' style={{transform: getForm ? "translate(-50%, -50%) scale(1)" : "translate(-50%, -50%) scale(0)"}}>
            {/* HEAD */}
            <div className="head">
                <h3>Add Playground</h3>
                <button onClick={()=>{ handleControllForm() }}>
                    <span className="material-symbols-outlined">close</span>
                </button>
            </div>
            {/* FORM */}
            <div className="form">
                <div className="inp">
                    <label>Name :</label>
                    <input type="text" placeholder="Add name..." onChange={(e)=>{ setName(e.target.value) }} value={name} />
                </div>
                <div className="inp">
                    <label>Select User :</label>
                    <select onChange={(e)=>{ setUserId(e.target.value) }} value={userId}>
                        <option value="">---Select User---</option>
                        {
                            getUsers.isFetched && getUsers.data.map((item, i)=>{
                                return <option key={i} value={item.id}>{item.username}</option>
                            })
                        }
                    </select>
                </div>
                <div className="inp">
                    <label>Select Tables :</label>
                    <div className="options">
                        {
                            types.map((item, i)=>{
                                return <button key={i} className={item.selected ? "selected" : ""}
                                        onClick={()=>{ handleTypes(item.name) }} >
                                            {item.name}
                                        </button>
                            })
                        }
                    </div>
                </div>
                <div className="inp">
                    <label>Creativity :</label>
                    <label className="switch">
                        <input type="checkbox" checked={creative} onChange={(e)=>{ setCreative(e.target.checked) }} />
                        <span className="slider"></span>
                    </label>
                </div>
                <div className="submit">
                    <button onClick={()=>{ handleControllForm() }}>Cancel</button>
                    <button onClick={()=>{ handleForm() }}>{reason == 'create' ? 'Create' : 'Update'}</button>
                </div>
            </div>
        </section>
    </>
  )
}
