import axios from "axios";
import { API_URL, TOKEN } from "../conf.js";

// GET ALL CONNECTORS
export async function getAllConnectors(page, per_page, search) {
  const response = await axios.get(
    `${API_URL}api/connectors?page=${page}&per_page=${per_page}&search=${search}`,
    {
      headers: { Authorization: `Bearer ${TOKEN}` },
    }
  );
  return response.data.data;
}

// GET CONNECTOR BY ID
export async function getOneConnector(id) {
  const response = await axios.get(`${API_URL}api/connectors/${id}`, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
  return response.data.data;
}

// CREATE A NEW CONNECTOR
export async function createConnector(data) {
  return await axios.post(`${API_URL}api/connectors`, data, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}

// DUPLICATE CONNECTOR
export async function duplicateConnector(id) {
  return await axios.post(`${API_URL}api/connectors/${id}/duplicate`, null, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}

// UPDATE CONNECTOR
export async function updateConnector(data) {
  const { name, description, type, connection_options, user_id } = data;
  return await axios.put(
    `${API_URL}api/connectors/${data.id_connector}`,
    {
      name,
      description,
      type,
      connection_options,
      user_id,
    },
    { headers: { Authorization: `Bearer ${TOKEN}` } }
  );
}

export async function deleteConnector(id) {
  return await axios.delete(`${API_URL}api/connectors/${id}`, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}
