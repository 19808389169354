import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { getAllConnectors } from "../../../api/ConnectorsApi";
import { useEtlContext } from "../../../context/EtlProvider";
import SuccessMsg from "../../dashboard/pop/SuccessMsg";
import { getEtlStat } from "../../../api/EtlApi";
import { useParams } from "react-router-dom";
import { API_URL, TOKEN } from "../../../conf";
import axios from "axios";
export default function OutputSettings({
  controll,
  setControll,
  nodeId,
  lastNodeId,
  updateNodeLabel,
}) {
  // POP MESSAGE
  const [msg, setMessage] = useState({ display: false, theme: "", name: "" });

  // FLOW ID
  const { id } = useParams();

  // CASH STATUS

  // CONNECTORS INFORMATION
  const getConnectors = useQuery(["getConnectors"], () =>
    getAllConnectors(1, 9999, "")
  );

  // ETL CONTEXT
  const { etlData, setEtlData } = useEtlContext();
  const [isOutputConnected, setIsOutputConnected] = useState(false);

  // FORM VALUES
  const [connector, setConnector] = useState("");
  const [name, setName] = useState("");
  const [shema, setShema] = useState("");
  const [table, setTable] = useState("");
  const [mode, setMode] = useState("append");
  const [collection, setCollection] = useState("");
  const [requiredField, setRequiredField] = useState(false);
  const [formStatus, setFormStatus] = useState("create");
  const [connectorType, setConnectorType] = useState("SQL");
  const [conOptions, setConnectorOptions] = useState({});
  const [lastTransformer, setLastTransformer] = useState("");
  const [schemas, setSchemas] = useState([]);

  // GET Extension
  function getExtension(file) {
    const parts = file.split(".");
    if (parts.length > 1) {
      const extension = parts.pop();
      return extension;
    } else {
      return "No extension found";
    }
  }

  useEffect(() => {
    const node = etlData.sources.find((item) => item.id === nodeId);
    const outputNode = etlData.outputs.find((item) => item.id === nodeId);

    if (node) {
      setFormStatus("update");
      setName(node.name);
      setTable(node.table ? node.table : node.path);
      setShema(
        node.connectorType == "S3"
          ? node.connectorOptions?.bucket_name
          : node.connectorOptions?.schema
      );
      setCollection(node.collection ? node.collection : "");
      setLastTransformer(lastNodeId);
      setConnector(node.connector_id);
      setConnectorOptions(node.connectorOptions);
    } else {
      setFormStatus("create");
      setName("");
      setTable("");
      setShema("");
      setConnector("");
      setLastTransformer(lastNodeId);
      setConnectorOptions({});
    }
  }, [nodeId, controll, etlData.outputs, etlData.sources, lastNodeId]);

  // SAVE CONFIG
  const saveConfig = () => {
    if (
      lastTransformer !== "" &&
      (table !== "" || collection !== "") &&
      connector !== "" &&
      ((connectorType.toLowerCase() === "mysql" && table !== "") ||
        (connectorType.toLowerCase() !== "mysql" && shema !== ""))
    ) {
      // CREATE A NEW OUTPUT
      let datasetInfo = etlData.sources.find(
        (item) => item.id === lastTransformer
      );

      // ****** Connector options *******

      let connectorOptionsCustom = {};
      if (connectorType.toLocaleLowerCase() === "s3") {
        connectorOptionsCustom = {
          endpoint: conOptions.endpoint,
          access_key: conOptions.access_key,
          secret_key: conOptions.secret_key,
          bucket_name: shema ? shema : "",
        };
      } else if (connectorType.toLocaleLowerCase() === "mongodb") {
        connectorOptionsCustom = {
          url: conOptions.url,
          user: conOptions.user,
          password: conOptions.password,
        };
      } else {
        connectorOptionsCustom = {
          url: conOptions.url,
          user: conOptions.user,
          password: conOptions.password,
          schema: shema ? shema : "",
        };
      }

      const datasetOutput = {
        id: nodeId,
        connector_id: connector,
        cached: true,
        connectorOptions: connectorOptionsCustom,
        contentOptions: {
          mode: mode,
        },
      };
      if (["s3"].includes(connectorType.toLowerCase())) {
        datasetOutput.type = "file";
        datasetOutput.connectorType = connectorType;
        datasetOutput.path = table;
        datasetOutput.fileType = getExtension(table);
      } else if (
        ["mysql", "oracle", "postgresql", "sql server"].includes(
          connectorType.toLowerCase()
        )
      ) {
        datasetOutput.type = "sql";
        datasetOutput.table = table;
      } else if (["mongodb"].includes(connectorType.toLowerCase())) {
        datasetOutput.type = "nosql";
        datasetOutput.collection = collection;
        // Additional properties for nosql type
      } else {
        return;
      }

      const dagOutput = {
        id: nodeId,
        output_dataset_id: nodeId,
        select_expression: `SELECT * FROM ${lastTransformer}`,
      };

      // ADD TO ETL
      if (formStatus === "create") {
        setEtlData({
          sources:
            etlData === undefined
              ? [datasetOutput]
              : [...etlData.sources, datasetOutput],
          transformers: etlData === undefined ? [] : [...etlData.transformers],
          outputs:
            etlData === undefined
              ? [dagOutput]
              : [...etlData.outputs, dagOutput],
        });
      } else {
        const nodeUpdatedDataset = etlData.sources.filter(
          (item) => item.id !== nodeId
        );
        const nodeUpdatedDag = etlData.outputs.filter(
          (item) => item.id !== nodeId
        );

        setEtlData({
          sources:
            etlData === undefined
              ? [datasetOutput]
              : [...nodeUpdatedDataset, datasetOutput],
          transformers: etlData === undefined ? [] : [...etlData.transformers],
          outputs:
            etlData === undefined
              ? [dagOutput]
              : [...nodeUpdatedDag, dagOutput],
        });
      }

      // SAVE MESSAGE
      setMessage({ display: true, theme: "save", name: "Output" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
      // CLOSE CONFIG
      closeConfig();
    } else {
      setRequiredField(true);
    }
  };
  const fetchSchemas = async (url, user, password, dbType) => {
    const response = await axios.post(
      `${API_URL}api/fetchSchemas`,
      {
        db_type: dbType,
        url: url,
        user: user,
        password: password,
      },
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    );
    if (response) {
      setSchemas(response.data.schemas);
    }
  };
  // SWITCH CONNECTOR
  useEffect(() => {
    if (connector && !getConnectors.isLoading) {
      const connectorData = getConnectors.data.data.find(
        (item) => item.id_connector == connector
      );

      updateNodeLabel(nodeId, table);
      if (
        connectorData?.type.toLowerCase() == "sql server" ||
        connectorData?.type.toLowerCase() == "oracle" ||
        connectorData?.type.toLowerCase() == "postgresql"
      ) {
        fetchSchemas(
          connectorData?.connection_options.url,
          connectorData?.connection_options.user,
          connectorData?.connection_options.password,
          connectorData?.type
        );
      }

      if (connectorData) {
        setConnectorType(connectorData.type);

        setConnectorOptions(connectorData.connection_options);
      }
    }
  }, [connector, getConnectors.data, getConnectors.isLoading]);

  // LAST TRANSFORMER
  // useEffect(() => {
  //     if(getStat.isFetched){
  //         const edgeConnected = getStat.data.edges.filter(item=>{
  //             return item.source == "output1"
  //         })
  //         setLastTrasformer(edgeConnected.length ? edgeConnected[edgeConnected.length-1].target : "***")
  //     }
  // }, [lastTrasformer, getStat.isFetched])

  // CLOSE CONFIG
  const closeConfig = () => {
    setControll(!controll);
    setRequiredField(false);

    // CLEAR FORM
    setName("");
    setTable("");
    setShema("");
    setConnector("");
    setLastTransformer("");
    setConnectorOptions({});
  };

  const renderConnectorSpecificFields = () => {
    if (connectorType === "S3") {
      return (
        <>
          <div className="inp">
            <label>Bucket *</label>
            <input
              type="text"
              value={shema}
              placeholder={`Enter bucket's name`}
              onChange={(e) => setShema(e.target.value)}
            />
            <div className="required-error">
              {requiredField && shema === ""
                ? `Oops! Bucket field is required.`
                : ""}
            </div>
          </div>
          <div className="inp">
            <label>Path *</label>
            <input
              type="text"
              placeholder={`Enter path's name`}
              value={table}
              onChange={(e) => setTable(e.target.value)}
            />
            <div className="required-error">
              {requiredField && table === ""
                ? `Oops! Path field is required.`
                : ""}
            </div>
          </div>
        </>
      );
    } else if (connectorType === "MongoDB") {
      return (
        <>
          <div className="inp">
            <label>Collection *</label>
            <input
              type="text"
              placeholder="Enter collection's name"
              value={collection}
              onChange={(e) => setCollection(e.target.value)}
            />
            <div className="required-error">
              {requiredField && collection === ""
                ? "Oops! Collection field is required."
                : ""}
            </div>
          </div>
        </>
      );
    } else if (connectorType.toLowerCase() === "mysql") {
      return (
        <div className="inp">
          <label>Table *</label>
          <input
            type="text"
            placeholder="Enter table's name"
            value={table}
            onChange={(e) => setTable(e.target.value)}
          />
          <div className="required-error">
            {requiredField && table === ""
              ? "Oops! Table field is required."
              : ""}
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="inp">
            <label>Schema </label>
            <select value={shema} onChange={(e) => setShema(e.target.value)}>
              <option value="">-- Select schema --</option>

              {schemas?.map((schema) => {
                return (
                  <option key={schema} value={schema}>
                    {schema}
                  </option>
                );
              })}
            </select>

            <div className="required-error">
              {requiredField && shema == ""
                ? "Oops! Schema field is required."
                : ""}
            </div>
          </div>
          <div className="inp">
            <label>Table *</label>
            <input
              type="text"
              placeholder="Enter table's name"
              value={table}
              onChange={(e) => setTable(e.target.value)}
            />
            <div className="required-error">
              {requiredField && table === ""
                ? "Oops! Table field is required."
                : ""}
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      {/* POP MESSAGE */}
      <SuccessMsg display={msg.display} theme={msg.theme} name={msg.name} />
      {/* CONFIG */}
      <div
        className="bg"
        style={{ display: controll ? "block" : "none" }}
      ></div>
      <div
        className={
          controll ? "settings source-set open" : "settings source-set"
        }
      >
        {/* HEAD */}
        <div className="head">
          <button onClick={() => closeConfig()}>
            <span className="material-symbols-outlined">
              arrow_back_ios_new
            </span>
          </button>
          <h3>Data Output</h3>
          <span></span>
        </div>
        {/* FORM */}
        <div className="setting-content">
          <div className="inps">
            <div className="inp">
              <label>* Select your dataset :</label>
              <input
                type="text"
                placeholder="Please Link the outputs"
                value={lastTransformer}
                onChange={(e) => setLastTransformer(e.target.value)}
                disabled
              />
              <div className="required-error">
                {requiredField && lastTransformer === ""
                  ? "Oops! Destination field is required."
                  : ""}
              </div>
            </div>
            <div className="inp">
              <label>* Select your destination :</label>
              <select
                value={connector}
                onChange={(e) => setConnector(e.target.value)}
              >
                <option value="">-- Select connector --</option>
                {!getConnectors.isLoading &&
                  getConnectors.data.data
                    .filter((item) => item.type != "API")
                    .map((item) => (
                      <option key={item.id_connector} value={item.id_connector}>
                        {item.name}
                      </option>
                    ))}
              </select>
              <div className="required-error">
                {requiredField && connector === ""
                  ? "Oops! Connector field is required."
                  : ""}
              </div>
              {connectorType && connector && !getConnectors.isLoading ? (
                <div>
                  {renderConnectorSpecificFields()}
                  <div>
                    <label htmlFor="" className="">
                      Mode
                    </label>
                    <div className="flex items-center gap-5">
                      <div className="flex items-center gap-2 ">
                        <input
                          type="radio"
                          name="mode"
                          value="append"
                          checked={mode === "append"}
                          onChange={(e) => setMode(e.target.value)}
                          id="append"
                        />
                        <label htmlFor="append">Append</label>
                      </div>
                      <div className="flex items-center gap-2">
                        <input
                          type="radio"
                          name="mode"
                          value="overwrite"
                          checked={mode === "overwrite"}
                          onChange={(e) => setMode(e.target.value)}
                          id="overwrite"
                        />
                        <label htmlFor="overwrite">Overwrite</label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="submit">
            <button onClick={() => closeConfig()}>Cancel</button>
            <button onClick={() => saveConfig()}>
              {formStatus === "create" ? "Save" : "Update"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
