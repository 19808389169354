import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  Background,
  EdgeRemoveChange,
  Controls,
  MiniMap,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useNodesState,
} from "reactflow";
import "reactflow/dist/style.css";
import SourceNode from "../components/dashboard/nodes/SourceNode";
import TransformNode from "../components/dashboard/nodes/TransformNode";
import OutputNode from "../components/dashboard/nodes/OutputNode";
import SourceSettings from "../components/dashboard/config/SourceSettings";
import TransformSettings from "../components/dashboard/config/TransformSettings";
import OutputSettings from "../components/dashboard/config/OutputSettings";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import SuccessMsg from "../components/dashboard/pop/SuccessMsg";
import ErrorMsg from "../components/dashboard/pop/ErrorMsg";
import { useParams } from "react-router-dom";
import { useEtlContext } from "../context/EtlProvider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllEtls, getEtlStat, putEtl } from "../api/EtlApi";
import RunLog from "../components/dashboard/pop/RunLog";
import { createEtlRun } from "../api/EtlRunApi";
import { createRun } from "../api/LogApi";
import { useStateContext } from "../context/ContextProvider";

// NODE TYPES
const nodeTypes = {
  source: SourceNode,
  transform: TransformNode,
  result: OutputNode,
};

export default function Flow() {
  // USER INFO

  // FLOW ID
  const { id } = useParams();

  // ETL CONTEXT
  const { etlData, setEtlData, setEtlId } = useEtlContext();

  // Set ETL ID in a useEffect to avoid setting state during render
  useEffect(() => {
    setEtlId(id);
  }, [id, setEtlId]);

  // ETL INFORMATIONS
  const getEtls = useQuery(["getEtl"], () => getAllEtls(1, 9999, ""));

  // CONFIG CONTROLL
  const [settings, setSettings] = useState(false);
  const [settingId, setSettingId] = useState(null);
  const [configType, setConfigType] = useState(null);

  // POP MESSGAE
  const [msg, setMessage] = useState({ display: false, theme: "", name: "" });
  const [error, setError] = useState({ display: false, text: "" });

  // LOADER
  const [submitLoder, setSubmitLoader] = useState(false);

  // CONFIG CONTROLL (SETTINGS)
  const displaySetting = (nodeId, type) => {
    setConfigType(type);
    setSettings(!settings);
    setSettingId(nodeId);
  };

  const initialNodes = [];

  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState([]);
  const [nodeToRemove, setNodeToRemove] = useNodesState("");

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection) => {
      if (connection.source === "output1") {
        const isOutputConnected = edges?.some(
          (edge) => edge.source === "output1"
        );
        if (isOutputConnected) {
          return;
        }
      }
      setEdges((prevEdges) => addEdge(connection, prevEdges));
    },
    [edges]
  );

  // ADD NODE
  const addNode = (type) => {
    let getPosition = { x: 0, y: 0 };
    if (type === "source") {
      getPosition.x = 100;
      getPosition.y = 150;
    } else if (type === "transform") {
      getPosition.x = 400;
      getPosition.y = 50;
    } else {
      getPosition.x = 700;
      getPosition.y = 150;
    }
    const typeNbr = nodes.filter((item) => item.type === type);
    const id =
      (type === "result"
        ? "output"
        : type === "source"
        ? "input"
        : "transformer") +
      (typeNbr.length + 1);

    const checkOutputNode = nodes.find((item) => item.type === "result");
    if (type === "result" && checkOutputNode) {
      setError({ display: true, text: "You cannot add more than one output" });
      setTimeout(() => {
        setError({ display: false, text: "" });
      }, 3000);
    } else {
      let label = "";
      let subLabel = `id = ${id}`;
      if (type === "source") {
        label = "Source : Select dataset";
      } else if (type === "transform") {
        label = "Transform : join users with prices";
      } else {
        label = "Output : type your output";
      }

      setNodes([
        ...nodes,
        {
          id: id,
          type: type,
          position: getPosition,
          data: {
            label: label,
            subLabel: subLabel,
            showSetting: displaySetting,
            remove: removeNode,
          },
        },
      ]);
    }
  };

  // REMOVE NODE
  const [deleteNode, setDelete] = useState(false);

  function remove() {
    setEtlData({
      sources: etlData.sources.filter((item) => item.id !== deleteNode),
      transformers: etlData.transformers.filter(
        (item) => item.id !== deleteNode
      ),
      outputs: etlData.outputs.filter((item) => item.id !== deleteNode),
    });
    setNodes(nodes.filter((elm) => elm.id !== deleteNode));
    setDelete("");
  }

  const removeNode = (nodeId) => {
    setDelete(nodeId);
    setMessage({ display: true, theme: "delete", name: "Node" });
    setTimeout(() => {
      setMessage({ display: false, theme: "", name: "" });
    }, 3000);
  };

  const updateNodeLabel = (nodeId, newLabel) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              label: newLabel,
            },
          };
        }
        return node;
      })
    );
  };

  useEffect(() => {
    if (deleteNode) remove();
  }, [deleteNode]);

  // REMOVE EDGE
  const removeEdge = (e, edge) => {
    setDelete(edge.id);
    setMessage({ display: true, theme: "delete", name: "Edge" });
    setTimeout(() => {
      setMessage({ display: false, theme: "", name: "" });
    }, 3000);
    setEdges(edges.filter((elm) => elm.id !== edge.id));
  };

  // UPDATE NODES & EDGES IN DATABASE
  const [flow, setFlow] = useState({ nodes: [], edges: [] });
  const queryClient = useQueryClient();

  const getFlows = useMutation(getEtlStat, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["etl"]);
      data.nodes.forEach((node) => {
        node.data.remove = removeNode;
        node.data.showSetting = displaySetting;

        // Add new labels
        if (!node.data.label) {
          if (node.type === "source") {
            node.data.label = "Source : Select dataset";
          } else if (node.type === "transform") {
            node.data.label = "Transform : join users with prices";
          } else if (node.type === "result") {
            node.data.label = "Output : Select dataset";
          }
        }
        node.data.subLabel = `id = ${node.id}`;
      });
      setNodes(data.nodes);
      setEdges(data.edges);
    },
  });

  useEffect(() => {
    getFlows.mutate(id);
  }, [id]);

  useEffect(() => {
    setFlow({ nodes: nodes, edges: edges });
  }, [nodes, edges]);

  // ======= RUN =========
  const [run, setRun] = useState(false);
  const [logsData, setLogsData] = useState({
    id: null,
    start_date: null,
    end_date: null,
    logs: null,
  });

  const runEtl = useMutation(createEtlRun, {
    onSuccess: () => {
      queryClient.invalidateQueries(["etl_run"]);
    },
  });

  // SAVE ETL WORK
  const saveWork = useMutation(putEtl, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["etl"]);
      setMessage({ display: true, theme: "save", name: "Flow" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);
      setSubmitLoader(false);
    },
  });

  // SAVE NODES & EDGES CHANGES
  const save = () => {
    setSubmitLoader(true);
    saveWork.mutate({ id_etl: id, stat: flow });
    getEtls.refetch();
  };

  // CREATE A NEW LOG
  const createLog = useMutation(createRun, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["run"]);
      setMessage({ display: true, theme: "run log", name: "run" });
      setTimeout(() => {
        setMessage({ display: false, theme: "", name: "" });
      }, 3000);

      const currentDate = new Date();
      const endDate = currentDate.toISOString().slice(0, 19).replace("T", " ");
      currentDate.setSeconds(currentDate.getSeconds() - 2);
      const startDate = currentDate
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      const payload = {
        id_run: data.id,
        start_date: startDate,
        end_date: endDate,
        logs: data.log.join(", "),
        metrics: data.state,
        code: "",
        carbon: "",
        id_etl: id,
      };
      runEtl.mutate(payload);

      setLogsData({
        id: payload.id_run,
        start_date: payload.start_date,
        end_date: "--",
      });

      setSubmitLoader(false);
      setRun(true);
    },
    onError: () => {
      setSubmitLoader(false);
      setError({ display: true, text: "Server Error!!" });
      setTimeout(() => {
        setError({ display: false, text: "" });
      }, 3000);
    },
  });

  const handleRun = () => {
    const checkOutputNode = nodes.find((item) => item.type === "result");

    if (nodes.length && checkOutputNode) {
      setSubmitLoader(true);
      const jsonPlan = getEtls.data.data.find((item) => item.id_etl === id);
      createLog.mutate(jsonPlan?.json_plan);
    } else {
      setError({
        display: true,
        text: "ETL Standstill: No input, no progress!",
      });
      setTimeout(() => {
        setError({ display: false, text: "" });
      }, 3000);
    }
  };

  return (
    <div className="flow">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 99 }}
        open={submitLoder}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SuccessMsg display={msg.display} theme={msg.theme} name={msg.name} />
      <ErrorMsg display={error.display} text={error.text} />
      {run && <RunLog display={run} setDisplay={setRun} logsInfo={logsData} />}
      <div className="node-actions">
        <div className="nodes">
          <div className="nd" onClick={() => addNode("source")}>
            <span className="material-symbols-outlined">extension</span>
            <p>Source</p>
          </div>
          <div className="nd" onClick={() => addNode("transform")}>
            <span className="material-symbols-outlined">pentagon</span>
            <p>Transform</p>
          </div>
          <div className="nd" onClick={() => addNode("result")}>
            <span className="material-symbols-outlined">folder_open</span>
            <p>Output</p>
          </div>
        </div>
        <div className="actions">
          <Tooltip title="Save">
            <button onClick={save}>
              <span className="material-symbols-outlined">save</span>
            </button>
          </Tooltip>
          <Tooltip title="Run">
            <button onClick={handleRun}>
              <span className="material-symbols-outlined">play_arrow</span>
            </button>
          </Tooltip>
        </div>
      </div>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onEdgeDoubleClick={removeEdge}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
      >
        <Controls />
        <Background />
        <MiniMap />
      </ReactFlow>

      {configType === "source" && (
        <SourceSettings
          nodeId={settingId}
          controll={settings}
          setControll={setSettings}
          flow={flow}
          updateNodeLabel={updateNodeLabel}
        />
      )}
      {configType === "transform" && (
        <TransformSettings
          nodeId={settingId}
          controll={settings}
          setControll={setSettings}
        />
      )}
      {configType === "output" && (
        <OutputSettings
          nodeId={settingId}
          controll={settings}
          setControll={setSettings}
          lastNodeId={
            edges.some((edge) => edge.source === "output1")
              ? edges[edges.length - 1].target
              : ""
          }
          updateNodeLabel={updateNodeLabel}
        />
      )}
    </div>
  );
}
