import React, { useEffect, useRef, useState } from "react";
import { Handle } from "reactflow";
import { useEtlContext } from "../../../context/EtlProvider";

export default function OutputNode({ data, id }) {
  // ACTIONS CONTROLL
  const [action, setAction] = useState(false);

  const actionsRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        setAction(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [actionsRef]);

  // NODE INFO
  const { etlData } = useEtlContext();

  // GET NODE NAME
  const [nodeName, setName] = useState("");
  useEffect(() => {
    if (etlData) {
      let node = etlData.sources.find((item) => {
        return item.id == id;
      });
      // node && setName(node.name)
    }
  }, [etlData]);

  return (
    <div className="node output">
      {/* HANDLE */}
      <Handle type="source" position="left" />
      {/* TEXT */}
      <div className="text">
        <span className="material-symbols-outlined">folder_open</span>
        <div className="info">
          <p>{data.label}</p>
          <div style={{ fontSize: "0.8em", color: "gray" }}>
            {data.subLabel}
          </div>
          {/* <h5>{nodeName != '' ? nodeName : "Output Data"}</h5> */}
        </div>
      </div>
      {/* ACTION BUTTON */}
      <button
        onClick={() => {
          setAction(!action);
        }}
      >
        <span className="material-symbols-outlined">more_horiz</span>
      </button>
      {/* ACTIONS */}
      <div
        className="actions"
        ref={actionsRef}
        style={{ display: action ? "block" : "none" }}
      >
        <div
          className="act"
          onClick={() => {
            data.showSetting(id, "output");
          }}
        >
          <span className="material-symbols-outlined">settings</span>
          <p>Setting</p>
        </div>
        <div
          className="act"
          onClick={() => {
            data.remove(id);
          }}
        >
          <span className="material-symbols-outlined">delete</span>
          <p>Delete</p>
        </div>
      </div>
    </div>
  );
}
