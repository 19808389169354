import {createContext, useContext, useState} from 'react'

const StateContext = createContext({
    user: null,
    token: null,
    setUser: () => {},
    setToken: () => {}
})

export const ContextProvider = ({children}) => {

    // >>>>>>>>>>> USER <<<<<<<<<<<
    const [user, setUser] = useState()        // USER INFO
    const [token, _setToken] = useState(localStorage.getItem('ACCESS_TOKEN_USER'))   // USER TOKEN 

    const setToken = (token)=>{
        _setToken(token)
        if(token){
            localStorage.setItem('ACCESS_TOKEN_USER', token)
        }else{
            localStorage.removeItem('ACCESS_TOKEN_USER')
        }
    }

  return (
        <StateContext.Provider value={{user, token, setToken, setUser}}>
            {children}
        </StateContext.Provider>
  )
}


export const useStateContext = () => useContext(StateContext)

