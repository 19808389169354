import React from 'react'
import UsersTable from '../../components/admin/users/UsersTable'
import ApexChart from '../../components/admin/layouts/ApexChart'

export default function Overview() {
  return (
    <div className="panel overview">
        <div className="statistics">
            <div className="table-info">
                <div className="title-card">
                    <h3>ETL Statistics</h3>
                </div>
                <ApexChart />
            </div>
            <div className="table-info">
                <div className="title-card">
                    <h3>SQL Requests</h3>
                </div>
                <div className="pie">
                    <div className="circle">
                        <h3>40%</h3>
                    </div>
                    <div className="info">
                        <div>
                            <span class="material-symbols-outlined">hdr_strong</span>
                            <p>60% Uvailable</p>
                        </div>
                        <div>
                            <span class="material-symbols-outlined">hdr_strong</span>
                            <p>40% Available</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="table-info">
            <div className="title-card">
                <h3>Users</h3>
            </div>
            <UsersTable limit={6} />
        </div>
    </div>
  )
}
