import { Box, Skeleton } from '@mui/material'
import React from 'react'

export default function CardLoader() {

    const fakeData = [1, 2, 3, 4, 5, 6]

  return (
    <div className='connector-cards'>
        {
            fakeData.map(fake=>{
                return <div key={fake} style={{backgroundColor: 'transparent', margin: "1em 0"}}>
                                <div style={{display: 'flex'}}>
                                    <Skeleton variant="circular" width={50} height={40} sx={{marginRight: "1em"}} /> 
                                    <div style={{width: '100%'}}>
                                        <Skeleton variant="text" sx={{ fontSize: '.7em' , width:'80%'}} />
                                        <Skeleton variant="text" sx={{ fontSize: '8px' , width:'40%', margin: '.8em 0'}} />
                                    </div>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'space-between', margin: "2em 0 0 0", width: "85%"}}>
                                    <Skeleton variant="text" sx={{ fontSize: '1em' , width:'30%'}} />
                                    <Skeleton variant="text" sx={{ fontSize: '1em' , width:'30%'}} />
                                </div>
                        </div>
            })
        }
        
    </div>
  )
}
