import React from 'react'
import { Link, useParams } from 'react-router-dom';
  
export default function RunLog({display, setDisplay, logsInfo}) {

    // ETL ID
    const {id} = useParams()

    return (
    <>
        {/* BG CONTENT */}
        <div className="bg-form" style={{display: display ? 'block' : 'none'}}></div>
        {/* CONTENT */}
        <div className='connector-form run-log' 
             style={{transform: display ? "translate(-50%, 0) scale(1)" : "translate(-50%, 0) scale(0)"}}>
            {/* HEAD */}
            <div className="head">
                <h3>Run ETL</h3>
                <button onClick={()=>{ setDisplay(false) }}>
                    <span className="material-symbols-outlined">close</span>
                </button>
            </div>
            
            {/* LOG */}
            <div className="log">
                {/* INFO */}
                <div className="infos">
                    <div className="info">
                        <div>
                            <span className="material-symbols-outlined">line_start_circle</span>
                            <h5>Run Id</h5>
                        </div>
                        <p>{logsInfo.id}</p>
                    </div>
                    <div className="info">
                        <div>
                            <span className="material-symbols-outlined">schedule</span>
                            <h5>Start Date</h5>
                        </div>
                        <p>{logsInfo.start_date}</p>
                    </div>
                    <div className="info">
                        <div>
                            <span className="material-symbols-outlined">schedule</span>
                            <h5>End Date</h5>
                        </div>
                        <p>{logsInfo.end_date}</p>
                    </div>
                </div>
                <hr />
                <div className="logs-loader"><span className='log-loader'></span></div>
            </div>
            {/* CONTROLL */}
            <div className="form">
                <div className="submit">
                    <button onClick={()=>{ setDisplay(false) }}>Cancel</button>
                    <Link to={`/user/etl_run/${id}`}>Explore Runs</Link>
                </div>
            </div>
        </div>
    </>
    
  )
}
