import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "../components/dashboard/layouts/Layout";
import { useQuery } from "@tanstack/react-query";
import { getOneConnector } from "../api/ConnectorsApi";
import { API_URL, TOKEN } from "../conf";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import Pagination from "../components/Pagination";

const Catalog = () => {
  const { id } = useParams();
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(true); // Global loading state
  const [tablesLoading, setTablesLoading] = useState(false); // Tables loading state
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // State to store the current search term
  const [perPage, setPerPage] = useState(10);

  const {
    data: connectorData,
    isLoading: connectorLoading,
    refetch,
  } = useQuery(["getConnector", id], () => getOneConnector(id), {
    enabled: false, // Initially disabled
  });

  useEffect(() => {
    if (connectorData) {
      const { type, connection_options } = connectorData;
      fetchData(
        type,
        connection_options.url,
        connection_options.user,
        connection_options.password
      );
    }
  }, [connectorData, page, searchTerm, perPage]);
  useEffect(() => {
    if (search) {
      // If there's a search term, reset page to 1 and set perPage to 9999
      setPage(1);
      setPerPage(9999);
    } else if (!search && perPage !== 10) {
      // If the search term is cleared and perPage is not 5, reset perPage to 5
      setPerPage(10);
    }
  }, [search, perPage]);

  useEffect(() => {
    if (id) {
      // Enable the query when id changes (switching connectors)
      setTables([]);
      setError("");
      setLoading(true);
      setTablesLoading(true); // Start tables loading
      refetch();
    }
  }, [id]);

  // useEffect(() => {
  //   if (!tables.length) {
  //     // Check if there are no tables or if tables is null
  //     if (connectorData?.type.toLowerCase() === "mysql") {
  //       setPage(1); // Set page to 1 only for MySQL
  //     }
  //   } else if (!tables.length) {
  //     setPage(1);
  //   }
  // }, [tables, connectorData]);
  const fetchData = async (dbType, url, user, password) => {
    try {
      setTablesLoading(true); // Start tables loading
      const response = await axios.post(
        `${API_URL}api/fetchTables?page=${page}&per_page=${perPage}&search=${search}`,
        {
          db_type: dbType,
          url: url,
          user: user,
          password: password,
        },
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      );
      if (response) {
        // // Convert search term to lowercase
        // const searchTermLowerCase = search.toLowerCase();
        // // Filter tables/collections based on case-insensitive search
        // const filteredTables = response.data.data.filter((table) =>
        //   table.toLowerCase().includes(searchTermLowerCase)
        // );
        setData(response.data);
        setTables(response.data.data);
      }
    } catch (error) {
      setTables([]);
      setError("Connector offline");
    } finally {
      setTablesLoading(false); // Stop tables loading
    }
  };

  return (
    <>
      <div className="flex bg-black bg-opacity-5 rounded-2xl items-center px-2 py-1  mb-10 w-1/2">
        <span class="material-symbols-outlined text-secondarycolor mr-2">
          search
        </span>
        <input
          className="p-3 w-full bg-transparent"
          type="text"
          placeholder="Search"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            setSearchTerm(e.target.value); // Update the search term
          }}
        />
      </div>
      <div className="top">
        <div className="p-[2em]">
          <h3 className="flex font-normal items-center text-lg pb-5">
            <Link to={`/user`} className="text-black underline">
              Connectors
            </Link>
            &#62;
            <Layout title={`${connectorData?.name}`} />
          </h3>
        </div>
      </div>
      {error ? (
        <div className="flex justify-center items-center h-[50vh] text-xl text-gray-600">
          <div className="flex flex-col gap-[5rem] justify-center items-center">
            <p className="text-[10rem] font-extrabold">404</p>
            <p className="font-medium">{error}</p>
          </div>
        </div>
      ) : (
        <>
          {tablesLoading ? ( // Show skeleton loading only when tables are loading
            // Skeleton loading
            <table>
              <tbody>
                {[1, 2, 3].map((_, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton variant="text" />
                    </td>
                    <td>
                      <Skeleton variant="text" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            // Render table data when tables are loaded
            <table>
              <thead>
                <tr>
                  {connectorData?.type.toLowerCase() === "mysql" ? (
                    <th>Tables</th>
                  ) : connectorData?.type.toLowerCase() === "mongodb" ? (
                    <th>Collections</th>
                  ) : (
                    <>
                      <th>Schemas</th>
                      <th>Tables</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {connectorData?.type.toLowerCase() === "mysql"
                  ? // Handle MySQL data structure
                    tables.map((table, i) => (
                      <tr key={i}>
                        <Link
                          to={`/user/sampleDb/${connectorData.id_connector}/${table}`}
                          className="underline"
                        >
                          <td
                            className="flex justify-between"
                            data-label="table"
                          >
                            {table}
                          </td>
                        </Link>
                      </tr>
                    ))
                  : connectorData?.type.toLowerCase() === "mongodb"
                  ? // Handle MongoDB data structure
                    tables.map((table, i) => (
                      <tr key={i}>
                        <Link
                          to={`/user/sampleDb/${connectorData.id_connector}/${table}`}
                          className="underline"
                        >
                          <td
                            className="flex justify-between items-center"
                            data-label="collections"
                          >
                            {table}
                          </td>
                        </Link>
                      </tr>
                    ))
                  : // Handle other connector types
                    Object.entries(tables).map(([schema, tablesArray], i) => (
                      <React.Fragment key={i}>
                        {tablesArray.map((table, j) => (
                          <tr key={`${i}-${j}`}>
                            <td data-label="schema">{schema}</td>
                            <Link
                              to={`/user/sampleDb/${connectorData.id_connector}/${table}`}
                              className="underline"
                            >
                              <td
                                className="flex justify-between"
                                data-label="table"
                              >
                                {table}
                              </td>
                            </Link>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
              </tbody>
            </table>
          )}
          <Pagination data={data} page={page} setPage={setPage} />
        </>
      )}
    </>
  );
};

export default Catalog;
