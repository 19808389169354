import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Loader from '../components/dashboard/layouts/Loader'
import SideBar from '../components/admin/layouts/SideBar'
import Header from '../components/admin/layouts/Header'
import '../resources/style/Admin.scss'
import { useStateContext } from './ContextProvider'
import axiosClient from '../api/Authentication'
import { Helmet } from 'react-helmet'

export default function AdminContext() {

  const { user, setUser, token } = useStateContext()
  const navigate = useNavigate();

  // CHECK USER (LOGIN => YES | NO)
  useEffect(() => {
      !token && navigate("/panel")
  }, [0])
  
  useEffect(() => {
    // GET USER INFO
    axiosClient.get('/user').then((data)=>{
      if(data.data.is_admin){
        setUser(data.data)
      }else{
        navigate("/")
      }
    })
  }, [])
  

  // USER INF LOADER
  if(!user){
    return <Loader theme="black" />
  }

  return (
    <div className="admin">
      <Helmet>
        <title>Admin | Hi Group</title>
      </Helmet>
        <SideBar />
        <div className="admin-content">
            <Header />
            <Outlet />
        </div>
        {/* LOADER */}
        {/* <Loader /> */}
    </div>
  )
}
