import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useStateContext } from '../../../context/ContextProvider'
import axiosClient from '../../../api/Authentication'

export default function SideBar() {

    const { user, setToken, setUser } = useStateContext()

    // LINKS
    const [links, setLinks] = useState([
      {name : "Overview", icon: "overview_key", path : "/admin"},
      {name : "Users", icon: "group", path : "/admin/users"},
      {name : "Playgound", icon: "extension", path : "/admin/playground"}
    ])

    const { pathname } = useLocation()
    const navigate = useNavigate();

    // LOGOUT
  const logout = () =>{
    axiosClient.post('/logout')
      .then(()=>{
        setUser({})
        setToken(false)
        navigate('/panel')
        window.location.reload()
      })
  }

  return (
    <div className='side-bar'>
        {/* INFO */}
        <div className="info">
          {/* LOGO */}
          <div className="logo">
              <h3><span>H</span>i Group</h3>
          </div>
          {/* LINKS */}
          <div className="links">
            <h5>Menu</h5>
            <ul>
              {
                  links.map((item, i)=>{
                    return <li key={i}>
                                <Link to={item.path} className={item.path == pathname ? "selected" : ""}>
                                    <span className="material-symbols-outlined">{item.icon}</span>
                                    <p>{item.name}</p>
                                </Link>
                            </li>
                  })
              }
            </ul>
          </div>
        </div>
        {/* PROFILE */}
        <div className="profile">
          <h5>Profile</h5>
          <div className="admin-card">
              <div className="card">
                <img src="https://www.mantruckandbus.com/fileadmin/media/bilder/02_19/219_05_busbusiness_interviewHeader_1485x1254.jpg" alt="" />
                <div>
                    <p>{user.username}</p>
                    <span>{user.email}</span>
                </div>
              </div>
              <button onClick={()=>{ logout() }}>
                  <span className="material-symbols-outlined">logout</span>
                  <p>Log Out</p>
              </button>
          </div>
        </div>
    </div>
  )
}
