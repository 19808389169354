import React, { useState } from 'react'

export default function EtlCard({data, search}) {
  return (
    <div className='etl-cards'>
        {
            data.map((item, i)=>{
                return item.name.toLowerCase().indexOf(search.toLowerCase()) > -1 && 
                <div className="card" key={i}>
                            <div className="info">
                                <span className="material-symbols-outlined">tenancy</span>
                                <div>
                                    <p>{item.name}</p>
                                    <h5>{item.description ? item.description : "---"}</h5>
                                </div>
                            </div>
                            
                        </div>
            })
        }
    </div>
  )
}
