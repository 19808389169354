import axios from "axios";
import { API_URL, TOKEN } from "../conf.js";

// GET ALL WORKFLOWS
export async function getAllWorkflows(page, per_page) {
  const response = await axios.get(
    `${API_URL}api/workflows?page=${page}&per_page=${per_page}`,
    { headers: { Authorization: `Bearer ${TOKEN}` } }
  );
  return response.data.data;
}

// GET WORKFLOW BY ID
export async function getOneWorkflow(id) {
  const response = await axios.get(`${API_URL}api/workflows/${id}`, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
  return response.data.data;
}

// CREATE A NEW WORKFLOW
export async function createWorkflow(data) {
  return await axios.post(`${API_URL}api/workflows`, data, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}

// DUPLICATE WORKFLOW
export async function duplicateWorkflow(id) {
  return await axios.post(`${API_URL}api/workflows/${id}/duplicate`, null, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}

// UPDATE WORKFLOW
export async function updateWorkflow(data) {
  const { name, description, id_etl, cron } = data;
  return await axios.put(
    `${API_URL}api/workflows/${data.id_workflow}`,
    {
      name,
      description,
      id_etl,
      cron,
    },
    { headers: { Authorization: `Bearer ${TOKEN}` } }
  );
}

// DELETE WORKFLOW
export async function deleteWorkflow(id) {
  return await axios.delete(`${API_URL}api/workflows/${id}`, {
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
}
