import React, { useState } from "react";
import { useStateContext } from "../../context/ContextProvider";
import { Link } from "react-router-dom";
import "../../resources/style/Settings.scss";
import Layout from "../../components/dashboard/layouts/Layout";
import ProfileForm from "../../components/dashboard/forms/ProfileForm";

const Settings = () => {
  const { user, setToken, setUser } = useStateContext();
  const [open, setOpen] = useState(false);

  return (
    <div className="p-[2rem]">
      <Layout title="Settings">
        <div className="connector-cards my-10">
          <div>
            <ProfileForm open={open} setOpen={setOpen} />
            <div className="card">
              <div className="info">
                <span class="material-symbols-outlined">person</span>
                <div className="flex justify-between w-full items-center">
                  <div>
                    <p>Profile</p>
                    <h5>manage your profile</h5>
                  </div>

                  <button
                    onClick={() => setOpen(!open)}
                    className="text-white bg-secondarycolor p-2 rounded-2xl text-sm hover:text-secondarycolor hover:bg-white"
                  >
                    <p>Manage</p>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {user.role ? (
            user.role.name.toLowerCase() == "admin" ? (
              <>
                <div className="card">
                  <div className="info">
                    <span class="material-symbols-outlined">group</span>
                    <div className="flex justify-between w-full items-center">
                      <div>
                        <p>Users</p>
                        <h5>manage users here</h5>
                      </div>

                      <Link
                        className="text-white bg-secondarycolor p-2 rounded-2xl text-sm hover:text-secondarycolor hover:bg-white"
                        to="users"
                        target="_self"
                      >
                        <p>Manage</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="info">
                    <span class="material-symbols-outlined">
                      admin_panel_settings
                    </span>
                    <div className="flex justify-between w-full items-center">
                      <div>
                        <p>Roles</p>
                        <h5>manage roles here</h5>
                      </div>

                      <Link
                        className="text-white bg-secondarycolor p-2 rounded-2xl text-sm hover:text-secondarycolor hover:bg-white"
                        to="roles"
                      >
                        <p>Manage</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </Layout>
    </div>
  );
};

export default Settings;
