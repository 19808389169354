import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useStateContext } from "../../../context/ContextProvider";

export default function Header() {
  const [settings, setSettings] = useState(false);

  const { user } = useStateContext();

  return (
    <header>
      {/* SIDE BAR CONTROLL */}
      {/* ADMIN INFO */}
      <div className="admin-info ">
        <span className="material-symbols-outlined">account_circle</span>
        <div className="admin-profile">
          <div className="more">
            <p>{user.username}</p>
            <p className="text-gray-500 font-extralight text-xs">
              {user.role ? user.role.name : ""}
            </p>
            <span>{user.email}</span>
          </div>
        </div>
      </div>
    </header>
  );
}
