import React, { useEffect, useState } from "react";
import { getOneConnector } from "../api/ConnectorsApi";
import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { API_URL, TOKEN } from "../conf";
import axios from "axios";
import Layout from "../components/dashboard/layouts/Layout";

const SampleDb = () => {
  const { id, table } = useParams();

  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState("");
  const {
    data: connectorData,
    isLoading: connectorLoading,
    refetch,
  } = useQuery(["getConnector", id], () => getOneConnector(id), {
    enabled: false, // Initially disabled
  });
  const fetchData = async (dbType, url, user, password) => {
    try {
      setDataLoading(true); // Start tables loading
      const response = await axios.post(
        `${API_URL}api/fetchSamples`,
        {
          db_type: dbType,
          url: url,
          user: user,
          password: password,
          tableName: table,
        },
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      );
      if (response) {
        // // Convert search term to lowercase
        // const searchTermLowerCase = search.toLowerCase();
        // // Filter tables/collections based on case-insensitive search
        // const filteredTables = response.data.data.filter((table) =>
        //   table.toLowerCase().includes(searchTermLowerCase)
        // );
        // Inside your fetchData function
        setData(
          response.data.records.map((record) => {
            // Filter out metadata fields if the database type is MongoDB
            if (connectorData.type === "mongodb") {
              const formattedRecord = {};
              for (const [key, value] of Object.entries(record)) {
                if (key !== "_id") {
                  // Check for special MongoDB types and format them accordingly
                  if (typeof value === "object") {
                    // Handle special types
                    if ("$numberDecimal" in value) {
                      formattedRecord[key] = parseFloat(value.$numberDecimal);
                    } else if ("$date" in value) {
                      formattedRecord[key] = new Date(
                        parseInt(value.$date.$numberLong)
                      );
                    } else {
                      // Unknown special type, handle as-is
                      formattedRecord[key] = value;
                    }
                  } else {
                    // Regular field, just copy it
                    formattedRecord[key] = value;
                  }
                }
              }
              return formattedRecord;
            } else {
              return record; // Return the record as is for other database types
            }
          })
        );
      }
    } catch (error) {
      setData([]);
    } finally {
      setDataLoading(false); // Stop tables loading
    }
  };

  useEffect(() => {
    if (connectorData) {
      const { type, connection_options } = connectorData;
      fetchData(
        type,
        connection_options.url,
        connection_options.user,
        connection_options.password
      );
    }
  }, [connectorData]);
  return (
    <div className="p-[2em]">
      <h3 className="flex font-normal items-center text-lg pb-5">
        <Link to={`/user`} className="text-black underline">
          Connectors
        </Link>
        &#62;
        <Link
          to={`/user/catalog/${connectorData?.id_connector}`}
          className="text-black underline"
        >
          <Layout title={`${connectorData?.name}`} />
        </Link>
        &#62;
        {table}
      </h3>
      {dataLoading ? (
        <p>Loading data...</p>
      ) : (
        <div className="overflow-x-auto mt-7">
          {data.length === 0 ? (
            <p>No data available.</p>
          ) : (
            <table className="w-full border-collapse">
              <thead>
                <tr>
                  {/* Dynamically generate table headers */}
                  {data.length > 0 &&
                    Object.keys(data[0]).map((key, index) => {
                      // Exclude _id field from table headers if present
                      if (key !== "_id") {
                        return (
                          <th key={index} className="border p-3">
                            {key}
                          </th>
                        );
                      }
                    })}
                </tr>
              </thead>
              <tbody>
                {/* Populate table rows with data */}
                {data.map((record, index) => (
                  <tr key={index}>
                    {Object.entries(record).map(([key, value], index) => {
                      // Exclude _id field from table rows if present
                      if (key !== "_id") {
                        // Convert special MongoDB types to strings
                        const displayValue =
                          typeof value === "object"
                            ? JSON.stringify(value)
                            : value;
                        return (
                          <td key={index} className="border p-3">
                            {displayValue}
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default SampleDb;
