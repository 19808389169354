import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosClient from "../../../api/Authentication";
import { useStateContext } from "../../../context/ContextProvider";

export default function SideBar({ active }) {
  const { setUser, setToken } = useStateContext();
  const navigate = useNavigate();
  const [isToolsOpen, setIsToolsOpen] = useState(false);

  // LINKS
  const [links, setLinks] = useState([
    {
      name: "Connectors",
      icon: "sensors",
      path: "/user",
      to: "_self",
    },
    {
      name: "Datasets",
      icon: "account_tree",
      path: "/user/datasets",
      to: "_self",
    },
    {
      name: "ETL",
      icon: "tenancy",
      path: "/user/etl",
      to: "_self",
    },
    {
      name: "Workflows",
      icon: "timer",
      path: "/user/workflow",
      to: "_self",
    },
    {
      name: "Tools",
      icon: "build_circle",
      children: [
        {
          name: "AI Chat",
          icon: "chat",
          path: "/user/ai",
          to: "_self",
        },
        {
          name: "Visualization",
          icon: "equalizer",
          path: "https://viz.hibigbi.com/",
          to: "_blank",
        },
        {
          name: "Data Science",
          icon: "donut_large",
          path: "https://ml.hibigbi.com/lab?token=mytoken",
          to: "_blank",
        },
      ],
    },
  ]);

  // LOGOUT
  const logout = () => {
    axiosClient.post("/logout").then(() => {
      setUser({});
      setToken(false);
      window.location.reload();
      navigate("/");
    });
  };

  const location = useLocation();

  return (
    <div className="side-bar">
      {/* LOGO */}
      <div className="logo">
        <img
          src={require("../../../resources/assets/images/logo.png")}
          alt=""
        />
        <div>
          <p>Hi Group</p>
          <span>Dashboard</span>
        </div>
      </div>
      {/* LINKS */}
      <div className="links">
        <ul>
          <section>
            {links.map((item, index) => {
              if (item.children) {
                return (
                  <li key={index} className="relative">
                    <button
                      onClick={() => setIsToolsOpen(!isToolsOpen)}
                      className="flex items-center w-full px-4 py-2 text-left hover:bg-gray-100"
                    >
                      <span className="material-symbols-outlined mr-2">
                        {item.icon}
                      </span>
                      <p>{item.name}</p>
                      <span className="material-symbols-outlined ml-auto">
                        {isToolsOpen ? "expand_less" : "expand_more"}
                      </span>
                    </button>
                    {isToolsOpen && (
                      <ul
                        className="tools"
                        
                      >
                        {item.children.map((child, childIndex) => (
                          <li key={childIndex}>
                            <Link
                              to={child.path}
                              className={`flex items-center px-4 py-2 ${
                                location.pathname === child.path
                                  ? "text-blue-600"
                                  : ""
                              }`}
                              target={child.to}
                            >
                              <span className="material-symbols-outlined mr-2">
                                {child.icon}
                              </span>
                              <p>{child.name}</p>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                );
              } else {
                return (
                  <li key={index}>
                    <Link
                      to={item.path}
                      className={`flex items-center px-4 py-2 ${
                        location.pathname === item.path ? "text-blue-600" : ""
                      }`}
                      target={item.to}
                    >
                      <span className="material-symbols-outlined mr-2">
                        {item.icon}
                      </span>
                      <p>{item.name}</p>
                    </Link>
                  </li>
                );
              }
            })}
          </section>
        </ul>
        {/* LOG OUT */}
        <ul className="logout">
          <li>
            <Link
              to="settings"
              className={`flex items-center px-4 py-2 ${
                location.pathname.includes("settings") ? "text-blue-600" : ""
              }`}
              target="_self"
            >
              <span className="material-symbols-outlined mr-2">settings</span>
              <p>Settings</p>
            </Link>
          </li>
          <li>
            <button
              onClick={logout}
              className="flex items-center w-full px-4 py-2 text-left hover:bg-gray-100"
            >
              <span className="material-symbols-outlined mr-2">logout</span>
              <p>Log Out</p>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}
