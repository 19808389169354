import React from 'react'

export default function Loader({theme}) {
  return (
    <div className="loader">
        <img 
          style={theme && {filter: "brightness(.1)"}}
          src={require('../../../resources/assets/images/loader.gif')} alt=""
         />
    </div>
  )
}
